import React, {useState,useEffect} from 'react';
import {notification, Button, Table, Popconfirm, Tabs, Row, Col} from 'antd';
import Spinner from '../../../spinner';
import {companyService, consignorService, userServices} from '../../../../services';
import NotificationMessage from "../../../modals/notification-message";
import {useFetchNotification} from "../../../../hooks/use-fetch-notification";
import ModalLegalEntityFullInfo from "../../../modals/legal-entity-full-info";
import {
    ACCOUNTANT_LEGAL_ENTITY_APPROVE, APPROVE_BECOME_CARRIER_ORDER, CONSIGNOR_DIRECTOR_PERMISSION,
    LAWYER_LEGAL_ENTITY_APPROVE,
    LOGIST_LEGAL_ENTITY_APPROVE, PERSONAL_RESPONSIBILITY_LEGAL_ENTITY_APPROVE
} from "../../../../helpers/user_permission.helper";
import ModalRefuseOrderToConsignor from "../../../modals/refuse-order-to-consignor";
import ModalApproveLegalEntity from "../../../modals/approve-legal-entity";
import SelectField from "../../../select-field";
import InputField from "../../../input-field";


export default function OrdersToConsignor() {

  const [needUpdate, setNeedUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [stopListUpdate, setStopListUpdate] = useState(false);

  const [data, setData] = useState(null);

  const [showRefuseModal, setShowRefuseModal] = useState(false);
  const [currentOrderInfo, setCurrentOrderInfo] = useState(null);

  const [showLogistApproveModal, setShowLogistApproveModal] = useState(false);

  const [currentLegalEntityId, setCurrentLegalEntityId] = useState(null);
  const [showLegalEntityFullInfoModal, setShowLegalEntityFullInfoModal] = useState(false);

  const [fieldsErrors] = useState({});

  const [{ notifications }] = useFetchNotification(true);

  const [listTypes] = useState([
      {
          Id: 1,
          Name: "Новые",
      },
      {
          Id: 2,
          Name: "Согласованные",
      },
      {
          Id: 3,
          Name: "Отменённые",
      }
  ]);

    const [filterParams, setFilterParams] = useState({
        listType: 1,
        legalEntityName: '',
    });


  const user = userServices.getUser();

  const agreeOrderByType = (orderId, approveType) => {
      setLoading(true);

        consignorService
            .approveOrder(orderId, approveType)
            .then((response) => {
                if (response.code === 200) {
                    notification.success(
                        {
                            message: 'Подтверждение заявки',
                            description: response.message
                        }
                    );

                    setNeedUpdate(true);
                    setStopListUpdate(false);
                } else {
                    notification.error(
                        {
                            message: 'Подтверждение заявки',
                            description: response.message
                        }
                    );
                }

                setLoading(false);
            })
            .catch(err => {
                notification.error(
                    {
                        message: 'Подтверждение заявки',
                        description: err
                    }
                );

                setLoading(false);
            });
  };

  const agreeOrder = (orderId) => {
        setLoading(true);

        consignorService
            .agreeApproveOrder(orderId)
            .then((response) => {
                if (response.code === 200) {
                    notification.success(
                        {
                            message: 'Подтверждение заявки',
                            description: response.message
                        }
                    );

                    setNeedUpdate(true);
                    setStopListUpdate(false);
                } else {
                    notification.error(
                        {
                            message: 'Подтверждение заявки',
                            description: response.message
                        }
                    );
                }

                setLoading(false);
            })
            .catch(err => {
                notification.error(
                    {
                        message: 'Подтверждение заявки',
                        description: err
                    }
                );

                setLoading(false);
            });
    };

  const showRefuseModalAction = (orderId, orderType) => {
      setCurrentOrderInfo({
          orderId: orderId,
          orderType: orderType
      });
      setShowRefuseModal(true);
  };

  const showLegalEntityInfoModalAction = (legalEntityId) => {
        setCurrentLegalEntityId(legalEntityId);
        setShowLegalEntityFullInfoModal(true);
  };

  const handleOpenLogistApproveOrderModal = (orderId, orderType, approveType) => {
      setCurrentOrderInfo({
          orderId: orderId,
          orderType: orderType,
          approveType: approveType,
      });
      setShowLogistApproveModal(true);
  }

  const getFieldErrors = (fieldName) => {
      return typeof fieldsErrors[fieldName] !== "undefined"
          ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
              return fieldErrors + "; " + error;
          })
          : null;
  };

  useEffect(() => {
    if (!stopListUpdate) {
        setLoading(true);

        companyService
            .getOrdersForConsignorList(filterParams.listType, filterParams.legalEntityName)
            .then(resp => {
                setData(resp);

                setLoading(false);
                setNeedUpdate(false);
                setStopListUpdate(true);
            })
            .catch(errMsg => notification.error(
                { message: "Получение списка заявок",
                    description: errMsg }));
    }
  }, [needUpdate, stopListUpdate]);

  const columns = [
    { title: "WebID", key: "id", render: (record) => (<b>{record.id}</b>) },
    { title: "Юр. лицо", key: "legalEntity", render: (record) => record.legalEntity.name },
    { title: "Тип", key: "type", render: (record) => record.type.alias },
    { title: "Дата подачи", dataIndex: "dateCreated", key: "dateCreated" },
    {
        title: "", key: "actions", render: (record) => {
            if (record.status === 'На согласовании') {
                let buttons = [];

                switch (record.type.name) {
                    case "default":
                        if (record.approves) {
                            record.approves.forEach((item) => {
                                if (user.isAccessTo(item.permission) && !item.approved) {
                                    buttons.push(
                                        <Button style={{marginLeft: "0px", width: "100%"}} onClick={() => handleOpenLogistApproveOrderModal(record.id, record.type.name, item.type)} size="small" className="small-button typical-btn-green">
                                            Согласовать {item.name}
                                        </Button>
                                    );
                                    // if (item.type === 'logist_type' && record.consignor.isVital) {
                                    //     buttons.push(
                                    //         <Button style={{marginLeft: "0px"}} onClick={() => handleOpenLogistApproveOrderModal(record.id, record.type.name)} size="small" className="small-button typical-btn-green">
                                    //             Согласовать {item.name}
                                    //         </Button>
                                    //     );
                                    // } else {
                                    //     buttons.push(
                                    //         <Button style={{marginLeft: "0px"}} onClick={() => agreeOrderByType(record.id, item.type)} size="small" className="small-button typical-btn-green">
                                    //             Согласовать {item.name}
                                    //         </Button>
                                    //     );
                                    // }
                                }
                            });
                        }

                        if (user.isAccessTo(PERSONAL_RESPONSIBILITY_LEGAL_ENTITY_APPROVE)) {
                            buttons.push(<Button style={{marginLeft: "0px", width: "100%"}} onClick={() => agreeOrder(record.id)} size="small" className="small-button typical-btn-green">Согласовать под личную отвественность</Button>);
                        }
                        break;
                    case "become_carrier":
                        if (user.isAccessTo(APPROVE_BECOME_CARRIER_ORDER)) {
                            buttons.push(
                                <Button style={{marginLeft: "0px", width: "100%"}} onClick={() => handleOpenLogistApproveOrderModal(record.id, record.type.name)} size="small" className="small-button typical-btn-green">Согласовать</Button>
                            );
                        }
                        break;
                }



                buttons.push(<Button style={{marginLeft: "0px", width: "100%"}} onClick={() => showRefuseModalAction(record.id, record.type.name)} size="small" className="small-button typical-btn-red">Отклонить</Button>);

                return (
                    <>
                        {buttons}
                    </>
                );
            }
        }
    },
      {
          title: "", key: "actions", render: (record) => {

              return (
                  <React.Fragment>
                      <Button style={{marginLeft: "0px", width: "100%" }} onClick={() => showLegalEntityInfoModalAction(record.legalEntity.id)} size="small" className="small-button typical-btn-blue">Подробнее</Button>
                  </React.Fragment>
              );
          }
      },
  ];

  return (
    <Spinner loading={loading} tip="Загрузка...">
      <NotificationMessage notifications={notifications} setStopListUpdate={setStopListUpdate} loading={false} />
        <Row>
            <Col lg={8} sm={24} >
                <SelectField
                    title=""
                    name="listType"
                    selectedValue={filterParams.listType}
                    values={listTypes}
                    onChange={(value) => {
                        setFilterParams({...filterParams, listType: value});
                        setStopListUpdate(false);
                    }}
                    placeholder="Укажите тип"
                    notFoundText={null}
                    showArrow={true}
                    style={{ width: "100%" }}
                    getErrors={getFieldErrors}
                />
            </Col>
        </Row>
        <Row>
            <Col lg={8} md={24} sm={24} xs={24}>
                <InputField
                    name="legalEntityName"
                    label="Наиминование юр.лица"
                    value={filterParams.legalEntityName}
                    setValue={(value) => {setFilterParams({...filterParams, legalEntityName: value})}}
                    getFieldErrors={()=>{}}
                />
            </Col>
        </Row>
        <Row gutter={48} style={{ paddingBottom: "20px", paddingRight: '0' }}>
            <Col lg={4} md={24} sm={24} xs={24}>
                <Button type="primary"
                        htmlType="button"
                        onClick={() => {
                            setNeedUpdate(true);
                            setStopListUpdate(false);
                        }}
                        style={{ backgroundColor: '#1CAA53', border: 'none', height: '40px' }}
                >
                    Применить
                </Button>
            </Col>
        </Row>


      <Table
        columns={columns}
        dataSource={data}
        locale={{ emptyText: 'Данные отсутствуют' }}
        rowKey="id"
        size="small"
        pagination={{
          pageSize: 50
        }}
      />

        <ModalRefuseOrderToConsignor isShow={showRefuseModal} setIsShow={setShowRefuseModal} orderId={currentOrderInfo ? currentOrderInfo.orderId : null} setNeedUpdate={setNeedUpdate} setStopListUpdate={setStopListUpdate} />
        <ModalLegalEntityFullInfo isShow={showLegalEntityFullInfoModal} setIsShow={setShowLegalEntityFullInfoModal} legalEntityId={currentLegalEntityId} />
        <ModalApproveLegalEntity isShow={showLogistApproveModal} setIsShow={setShowLogistApproveModal} orderInfo={currentOrderInfo} setStopListUpdate={setStopListUpdate} />
    </Spinner>
  );
}
