import React, { useState } from "react";
import { Button, Col, Modal, Row, Spin, Form, notification } from "antd";
import { companyService } from "../../../services/";
import InputField from "../../input-field";

export default function ModalCreateEmployee({ isShow, setIsShow, onSuccess }) {
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState(null);
  const [secondName, setSecondName] = useState(null);
  const [patronymic, setPatronymic] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordRepeat, setPasswordRepeat] = useState(null);
  const [fieldsErrors, setFieldsErrors] = useState({});

  let bigButtonStyle = { width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px', color: "white", borderColor: "transparent" };

  const onSubmitFormCreateEmployee = e => {
    e.preventDefault();

    setLoading(true);

    companyService
      .createEmployee({ phone, email, firstName, secondName, patronymic, password, passwordRepeat })
      .then(responseData => {
        if (responseData.status === "success") {
          notification.success({
            message: "Создание сотрудника",
            description: responseData.message
          });
          setLoading(false);
          setIsShow(false);
          onSuccess();
        } else if (responseData.status === "error") {
          if (responseData.message) {
            notification.error({
              message: "Создание сотрудника",
              description: responseData.message
            });
          }
          setLoading(false);
          if (responseData.errors) {
            setFieldsErrors(responseData.errors);
          }
        }
      })
      .catch(errorMsg => {
        notification.error({
          message: "Создание сотрудника",
          description: errorMsg
        });
        setLoading(false);
      });
  };

  const getFieldErrors = fieldName => {
    return typeof fieldsErrors[fieldName] !== "undefined"
      ? fieldsErrors[fieldName].reduce((fieldErrors, error) => {
        return fieldErrors + "; " + error;
      })
      : null;
  };

  return (
    <Modal
      title={`Создание сотрудника`}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(false);
      }}
      footer={[
        <Button htmlType="submit" key="submit" style={bigButtonStyle} type="primary" onClick={onSubmitFormCreateEmployee}>
          Создать
        </Button>
      ]}
    >
      <Spin spinning={loading} tip="Загрузка...">
        <Form>
          <Row gutter={[16, 24]}>
            <Col lg={8} md={24} sm={24} xs={24}>
              <InputField name="firstName" label="Имя" value={firstName} setValue={setFirstName} getFieldErrors={getFieldErrors} />
            </Col>
            <Col lg={8} md={24} sm={24} xs={24}>
              <InputField name="secondName" label="Фамилия" value={secondName} setValue={setSecondName} getFieldErrors={getFieldErrors} />
            </Col>
            <Col lg={8} md={24} sm={24} xs={24}>
              <InputField name="patronymic" label="Отчество" value={patronymic} setValue={setPatronymic} getFieldErrors={getFieldErrors} />
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col lg={12} md={24} sm={24} xs={24}>
              <InputField name="phone" label="Моб. Телефон" mask="+7 (111) 111-11-11" value={phone} setValue={setPhone} getFieldErrors={getFieldErrors} />
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <InputField name="email" label="Email" value={email} setValue={setEmail} getFieldErrors={getFieldErrors} />
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col lg={12} md={24} sm={24} xs={24}>
              <InputField name="password" label="Пароль" value={password} setValue={setPassword} getFieldErrors={getFieldErrors} type="password" />
            </Col>
            <Col lg={12} md={24} sm={24} xs={24}>
              <InputField
                name="passwordRepeat"
                type="password"
                label="Повторение пароля"
                value={passwordRepeat}
                setValue={setPasswordRepeat}
                getFieldErrors={getFieldErrors}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
}
