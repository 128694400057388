import React, { useEffect, useState, useMountEffect } from 'react';
import { Button, Col, Table, Row, notification } from 'antd';
import ModalCreateDriver from "../../../../modals/create-driver";
import ModalEditDriver from "../../../../modals/edit-driver";
import Spinner from "../../../../spinner";
import { tableHelper } from '../../../../../helpers';
import { companyService } from "../../../../../services";
import ModalShowCarParkInfo from "../../../../modals/show-car-park-info";

export default function Drivers({ companyId }) {

	const [showModalCreateDriver, setShowModalCreateDriver] = useState(false);
	const [showModalEditDriver, setShowModalEditDriver] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [isShowModal, setIsShowModal] = useState(false);

	const [loading, setLoading] = useState(false);
	const [isNeedUpdateList, setIsNeedUpdateList] = useState(true);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [data, setData] = useState([]);


	let bigButtonStyle = { width: '190px', height: '40px', background: '#1CAA53', position: 'static', borderRadius: '10px', float: 'right', borderColor: "#1CAA53" };
	let editButtonStyle = { fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '600', fontSize: '10px', lineHeight: '12px', color: '#2B3F63', borderRadius: '0px', borderColor: 'transparent', background: "transparent", boxShadow: 'none', padding: "0px" };
	let deleteButtonStyle = { fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '600', fontSize: '10px', lineHeight: '12px', color: '#EB5757', borderRadius: '0px', borderColor: 'transparent', background: "transparent", boxShadow: 'none' };

	useEffect(() => {
		if (isNeedUpdateList) {
			setLoading(true);
			setIsNeedUpdateList(false);
			companyService.getCompanyDrivers().then((response) => {
				setData(response);
				setDataLoaded(true);
				setLoading(false);
			})
		}
	}, [isNeedUpdateList]);


	const columns = [
		{
			title: 'Фамилия',
			dataIndex: 'surname',
			key: 'surname',
			width: '14%',
			sorter: (a, b) => {
				return a.surname.localeCompare(b.surname);
			},
		},
		{
			title: 'Имя',
			dataIndex: 'firstName',
			key: 'firstName',
			width: '12%',
			sorter: (a, b) => {
				return a.firstName.localeCompare(b.firstName);
			},
		},
		{
			title: 'Отчество',
			dataIndex: 'lastName',
			key: 'lastName',
			width: '15%',
			sorter: (a, b) => {
				return a.lastName.localeCompare(b.lastName);
			},
		},
		{
			title: 'Телефон',
			dataIndex: 'phone',
			key: 'phone',
			width: '12%',
			sorter: (a, b) => {
				return Number(a.phone) - Number(b.phone);
			},
		},
		{
			title: 'Гражданство',
			dataIndex: 'regCountry',
			key: 'regCountry',
			width: '12%',
			sorter: (a, b) => {
				return a.regCountry.localeCompare(b.regCountry);
			},
		},
		{
			title: 'Выполнено рейсов',
			dataIndex: 'deliveryCount',
			key: 'deliveryCount',
			width: '12%',
			sorter: (a, b) => {
				return Number(a.deliveryCount) - Number(b.deliveryCount);
			},
		},
		{
			title: 'Заработано',
			dataIndex: 'salary',
			key: 'salary',
			width: '12%',
			sorter: (a, b) => {
				return Number(a.salary) - Number(b.salary);
			},
		},
		{
			title: 'Анкета',
			key: 'questionnaire',
			render: (data) => {
				return (
					<React.Fragment>
						<Button
							htmlType="button"
							onClick={() => setShowModalEdit(data.id)}
							size="small"
							style={editButtonStyle}>
							Посмотреть
						</Button>
						<ModalShowCarParkInfo
							isShow={(showModalEdit === data.id)}
							onCloseModal={(isNeedRefresh = false) => {
								setShowModalEdit(false);
								setLoading(isNeedRefresh);
								setIsNeedUpdateList(isNeedRefresh);
							}}
							driverId={data.id}
							info={data}
						/>
					</React.Fragment >
				);
			}
		},
		{
			title: 'Действия',
			key: 'edit',
			render: (data) => {
				return (
					<React.Fragment>
						<Button
							htmlType="button"
							onClick={() => setShowModalEditDriver(data.id)}
							size="small"
							style={editButtonStyle}>
							Редактировать
						</Button>
						<ModalEditDriver
							isShow={(showModalEditDriver === data.id)}
							onCloseModal={() => {
								setShowModalEditDriver(null);
								setLoading(true);
								setIsNeedUpdateList(true);
							}}
							driverId={data.id}
						/>
					</React.Fragment >
				);
			}
		},
		/* {
			title: '',
			key: 'delete',
			render: (data) => {
				return (
					<React.Fragment>
						<Button
							htmlType="button"
							size="small"
							style={deleteButtonStyle}
						>
							Удалить
						</Button>
					</React.Fragment>
				);
			}
		} */
	];

	return (
		<React.Fragment>
			<Spinner loading={loading} tip="Загрузка...">
				<Row style={{ marginBottom: "25" }}>
					<Col className={"content-header"} style={{ marginBottom: "0px" }} span={12}>Водители</Col>
					<Col span={8} style={{ marginBottom: "0px" }} offset={4}>
						<Button type="primary"
							htmlType="button"
							style={bigButtonStyle}
							onCloseModal={() => {
								setIsNeedUpdateList(true);
							}}
							onClick={() => {
								setIsShowModal(false);
								setShowModalCreateDriver(true);
							}}>

							Создать водителя
						</Button>
						<ModalCreateDriver
							companyId={companyId}
							isShow={showModalCreateDriver}
							setIsShow={setShowModalCreateDriver}
							setShowAttachDriverOrderDelivery={setIsShowModal}
							setIsNeedUpdateList={setIsNeedUpdateList}
						/>
					</Col>
				</Row>
				<Col span={24}>
					<Table
						style={{ minWidth: "700px" }}
						columns={columns}
						dataSource={data}
						locale={{ emptyText: "Данные отсутствуют" }}
						rowKey="myid"
						size="small"
						pagination={{
							pageSize: 50,
						}}
						className="car-park"
						rowClassName={(record, index) => ("car-park-row")
						}
					/>

				</Col>
			</Spinner>
		</React.Fragment >
	);
}
