/** @var Типы загружаемых документов*/
export const legalEntityDataAliases = {
  // RU
  // Основная информация
  OrgForm: 'Организационная форма',
  DateRegistration: 'Дата регистрации',
  Name: 'Название',
  WorkWithNds: 'НДС',
  LegalReason: 'Основание действий',
  FirstName: 'Имя',
  SecondName: 'Фамилия',
  Patronymic: 'Отчество',
  Inn: 'ИНН',
  Kpp: 'КПП',
  Ogrn: 'ОГРН',
  Ogrnip: 'ОГРНИП',
  Okpo: 'ОКПО',
  Rs: 'Расчётный счёт',
  Bic: 'БИК',
  BankName: 'Название банка',
  Country: 'Страна',
  RegAddress: 'Фактический адрес',
  MailingAddress: 'Почтовый адрес',
  LegalAddressPostalCode: 'Юридический адрес',
  AtiCode: 'Код АТИ',
  Position: 'Должность',
  PositionStr: 'Должность',
  GuarantorFirstName: 'Имя поручителя',
  GuarantorSecondName: 'Фамилия поручителя',
  GuarantorPatronymic: 'Отчество поручителя',
  GuarantorPassportSerial: 'Серия паспорта поручителя',
  GuarantorPassportNumber: 'Номер паспорта поручителя',
  GuarantorPassportDate: 'Дата выдачи паспорта поручителя',
  GuarantorPassportDivision: 'Код подразделения паспорта поручителя',
  GuarantorAddressRegistration: 'Адрес регистрации поручителя',
  GuarantorAddressFact: 'Фактический адрес поручителя',

  // Документы
  certificateOfStateRegistration: 'Свидетельство о государственной регистрации в качестве индивидуального предпринимателя',
  certificateOfRegistrationIndividualEntrepreneurWithTaxAuthority: 'Свидетельство о постановке индивидуального предпринимателя на учет в налоговом органе',
  dischargeFromEGRIP: 'Выписка из ЕГРЮЛ',
  partnerCard: 'Карта партнера с образцом подписи и печати',
  certificateOfCalculationsWithTheBudget: 'Справка о расчетах с бюджетом по налогам, сборам, пеням, штрафам, процентам, выданная налоговым органом',
  copyOfThePassport: 'Копия паспорта',
  decisionToCreateSociety: 'Решение о создании общества',
  charterWithAllAmendments: 'Устав со всеми изменениями',
  protocolOfTheGeneralMeetingOfParticipants: 'Протокол общего собрания участников юридического лица с решением об избрании руководителя',
  certificateOfEntry: 'Свидетельство о внесении записи о юридическом лице, зарегистрированном до 01.07.2002',
  dischargeFromEGRUL: 'Выписка из ЕГРЮЛ',
  powerOfAttorneyForAPerson: 'Доверенность на лицо, подтверждающая полномочия лица, подписывающего договор',
  bookkeepingBalance: 'Бухгалтерский баланс за последний отчетный период',
  profitsAndLossesReport: 'Отчет о прибылях и убытках',
  incomeTaxAndNDSDeclaration: 'Декларация по налогу на прибыль и НДС (если работает с НДС) с отметкой налогового органа о принятии',
  proofOfOwnership: 'Документы, подтверждающие право собственности или иные права на офисные помещения, в которых контрагент осуществляет деятельность',
  copyOfBankCard : 'Копия банковской карточки с образцами подписей и оттиском печати',
  incomeTaxForTheLastReportingPeriod: 'Налоговая декларация по прибыли за последний отчетный период',
  nDSReturnForTheLastReportingPeriod: 'Налоговая декларация по НДС за последний отчетный период',
  certificateFromIFNSOnTheStatusOfSettlementsWithTheBudget: 'Справка из ИФНС о состоянии расчетов с бюджетом',
  staffingTable: 'Штатное расписание',
  registeredMemorandumOfAssociation: 'Зарегистрированный Учредительный договор',
  decisionToCreate: 'Протокол или Решение о создании',
  requisites: 'Реквизиты с печатью',
  registrationOfALegalEntityWithTaxAuthority: 'Свидетельство о постановке на учет в налоговом органе',

  // Belarus
  Email: 'Email',
  Phone: 'Моб. номер',
  LegalNumber: 'УНП',
  BankUnp: 'УНП Банка',
  BankAddress: 'Адрес Банка',
  AccountNumber: 'Номер счета',
  Swift: 'SWIFT',
  Address: 'Адрес регистрации',
  GuarantorCountry: 'Страна поручителя',
  GuarantorPassportRegistration: 'Регистрация паспорта поручителя',
  GuarantorPhone: 'Номер поручителя',
  GuarantorEmail: 'Email поручителя',

  passport: 'Паспорт',
  specialPermitForTheRightToCarryOutActivities: 'Специальное разрешение (лицензия) на право осуществления деятельности в области автомобильного транспорта',
  residenceCertificate: 'Справка о постоянном местонахождении Residence Certificate (в целях избежания двойного налогообложения)',
  articlesOfAssociation: 'Устав в актуальной редакции',
  documentsConfirmingOwnershipOfOfficePremises: 'Документы, подтверждающие право собственности или иные права на офисные помещения',

};

export const DOCS_COMBINATION = {
  basic: [{docName:'registrationOfALegalEntityWithTaxAuthority', isMultiple: true},
    {docName:'charterWithAllAmendments', isMultiple: true},
    {docName:'proofOfOwnership', isMultiple: true},
    {docName:'protocolOfTheGeneralMeetingOfParticipants', isMultiple: true},
    {docName:'partnerCard', isMultiple: true},
    {docName:'copyOfThePassport', isMultiple: true},
    {docName:'certificateOfStateRegistration', isMultiple: true}
  ],
  isNdsPayer: [{docName:'incomeTaxForTheLastReportingPeriod', isMultiple: true},
    {docName:'nDSReturnForTheLastReportingPeriod', isMultiple: true},
    {docName:'bookkeepingBalance', isMultiple: true},
    {docName:'certificateFromIFNSOnTheStatusOfSettlementsWithTheBudget', isMultiple: true},
    {docName:'staffingTable', isMultiple: true}],
  isNeedPowerOfAttorneyForAPerson: [{docName:'powerOfAttorneyForAPerson', isMultiple: true}],
  isProprietor: [{docName:'partnerCard', isMultiple: true},
    {docName:'copyOfThePassport', isMultiple: true},
    {docName:'certificateOfStateRegistration', isMultiple: true},
    {docName:'certificateOfRegistrationIndividualEntrepreneurWithTaxAuthority', isMultiple: true}],
  organizationalForm: {
    26: [{docName:'partnerCard', isMultiple: true},
      {docName:'copyOfThePassport', isMultiple: true},
      {docName:'requisites', isMultiple: true},
      {docName:'decisionToCreate', isMultiple: true},
      {docName:'registeredMemorandumOfAssociation', isMultiple: true},
      {docName:'certificateOfStateRegistration', isMultiple: true},
      {docName:'certificateOfRegistrationIndividualEntrepreneurWithTaxAuthority', isMultiple: true}
    ]
  }
}


export const FORM_FIELDS_RUS_LEGAL_ENTITY = [
  {key:"Name", title: "Название"},
  {key:"FullName", title: "Полное название"},
  {key:"FirstName", title: "Имя"},
  {key:"Patronymic", title: "Отчество"},
  {key:"SecondName", title: "Фамилия"},
  {key:"Bic", title: "БИК банка"},
  {key:"Rs", title: "Номер счёта"},
  {key:"RegAddress", title: "Адрес"},
  {key:"LegalAddress", title: "Юридический адрес"},
  {key:"MailingAddress", title: "Почтовый адрес"}
]
export const FORM_FIELDS_KAZ_LEGAL_ENTITY = [
  {key:"Name", title: "Название"},
  {key:"FullName", title: "Полное название"},
  {key:"FirstName", title: "Имя"},
  {key:"Patronymic", title: "Отчество"},
  {key:"SecondName", title: "Фамилия"},
  {key:"Bic", title: "БИК банка"},
  {key:"BankName", title: "Наименование банка"},
  {key:"Rs", title: "Номер счёта"},
  {key:"RegAddressRegion", title: "Регион"},
  {key:"RegAddressLocality", title: "Населенный пункт"},
  {key:"RegAddressStreet", title: "Улица"},
  {key:"RegAddressHouseNumber", title: "Номер дома"},
  {key:"RegAddressPremiseNumber", title: "Номер помещения"},
  {key:"RegAddressPostalCode", title: "Почтовый индекс"},
  {key:"LegalAddressRegion", title: "Регион"},
  {key:"LegalAddressStreet", title: "Улица"},
  {key:"LegalAddressLocality", title: "Населенный пункт"},
  {key:"LegalAddressHouseNumber", title: "Номер дома"},
  {key:"LegalAddressPremiseNumber", title: "Номер помещения"},
  {key:"LegalAddressPostalCode", title: "Почтовый индекс"}
]
export const FORM_FIELDS_BEL_LEGAL_ENTITY = [
  {key:"Name", title: "Название"},
  {key:"FullName", title: "Полное название"},
  {key:"FirstName", title: "Имя"},
  {key:"Patronymic", title: "Отчество"},
  {key:"SecondName", title: "Фамилия"},
  {key:"Bic", title: "БИК банка"},
  {key:"BankName", title: "Наименование банка"},
  {key:"AccountNumber", title: "Номер счёта"},
  {key:"AddressCity", title: "Город"},
  {key:"Address", title: "Полный адрес"},
  {key:"MailingAddress", title: "Почтовый адрес"}
]
