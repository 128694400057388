import React, {useState, useEffect} from 'react';
import {getCertificate, getUserCertificates} from 'crypto-pro';
import {Col, Collapse, Row, Select} from "antd";
import moment from "moment/moment";

function Certificate({onChange}) {
  const [certificates, setCertificates] = useState([]);
  const [certificatesError, setCertificatesError] = useState([]);
  const [certificate, setCertificate] = useState(null);
  const [certificateDetails, setCertificateDetails] = useState(null);
  const [detailsError, setDetailsError] = useState(null);

  function selectCertificate(value) {
    const certificate = certificates.find(({thumbprint}) => thumbprint === value);

    setCertificate(certificate);
    onChange(certificate);
  }

  async function loadCertificateDetails(thumbprint) {
    try {
      const certificate = await getCertificate(thumbprint);

      setCertificateDetails({
        name: certificate.name,
        issuerName: certificate.issuerName,
        subjectName: certificate.subjectName,
        thumbprint: certificate.thumbprint,
        validFrom: certificate.validFrom,
        validTo: certificate.validTo,
        isValid: await certificate.isValid(),
        version: await certificate.getCadesProp('Version'),
        base64: await certificate.exportBase64(),
        algorithm: await certificate.getAlgorithm(),
        extendedKeyUsage: await certificate.getExtendedKeyUsage(),
        ownerInfo: await certificate.getOwnerInfo(),
        issuerInfo: await certificate.getIssuerInfo(),
        decodedExtendedKeyUsage: await certificate.getDecodedExtendedKeyUsage(),
        '1.3.6.1.4.1.311.80.1': await certificate.hasExtendedKeyUsage('1.3.6.1.4.1.311.80.1'),
        '[\'1.3.6.1.5.5.7.3.2\', \'1.3.6.1.4.1.311.10.3.12\']': await certificate.hasExtendedKeyUsage([
          '1.3.6.1.5.5.7.3.2',
          '1.3.6.1.4.1.311.10.3.12'
        ]),
        '1.3.6.1.4.1.311.80.2': await certificate.hasExtendedKeyUsage('1.3.6.1.4.1.311.80.2'),
        '\'1.3.6.1.5.5.7.3.3\', \'1.3.6.1.4.1.311.10.3.12\'': await certificate.hasExtendedKeyUsage([
          '1.3.6.1.5.5.7.3.3',
          '1.3.6.1.4.1.311.10.3.12'
        ]),
      });
    } catch (error) {
      setDetailsError(error);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        setCertificates(await getUserCertificates());
      } catch (error) {
        setCertificatesError(error.message);
      }
    })();
  });

  const {Option} = Select;
  const { Panel } = Collapse;
  return (
    <>
      <Row gutter={[16, 24]} style={{marginBottom: "34px"}}>
        <Col span={24} style={{fontSize: "medium"}}>
          Выберите сертификат:<sup style={{color: "red"}}>*</sup>
        </Col>
        <Col span={24}>
          <Select id="certificate" defaultValue={null} onChange={selectCertificate} style={{width: "100%"}}>
            {certificates.map(({name, thumbprint, validTo}) =>
              <Option key={thumbprint} value={thumbprint} style={{width: "100%"}}>
                {name + ' (действителен до: ' + moment(validTo).format("DD.MM.YYYY") + ')'}
              </Option>
            )}
          </Select>
          <pre>{certificatesError || null}</pre>
        </Col>
      </Row>

      <Row gutter={[16, 24]} style={{marginBottom: "34px"}}>
        <Col span={24}>
          {certificate ? (
            <>
            <Collapse onChange={()=> loadCertificateDetails(certificate.thumbprint)}>
              <Panel header="Информация о сертификате" key="1">
                <pre>
              {certificateDetails ? (
                JSON.stringify(certificateDetails, null, '  ')
              ) : 'Запрашивается...'}
            </pre>
                <pre>{detailsError || null}</pre>
              </Panel>
            </Collapse>

            </>
          ) : null}
        </Col>
      </Row>
    </>
  );
}

export default Certificate;
