import React, {useState} from "react";
import {createDetachedSignature, createHash} from "crypto-pro";
import Certificate from "./certificate";
import {Button, Col, Modal, Row} from "antd";

export default function CreateSignature({message, userId, signature, setSignature}) {
  const [certificate, setCertificate] = useState(null);
  const [hash, setHash] = useState('');
  const [hashStatus, setHashStatus] = useState('Не вычислен');
  const [hashError, setHashError] = useState(null);
  const [signatureStatus, setSignatureStatus] = useState('Не создана');
  const [signatureError, setSignatureError] = useState(null);
  const [fileData, setFileData] = useState('');

  async function createSignature(event) {
    let hash;

    event.preventDefault();

    setSignature('');
    setSignatureError(null);

    setHash('');
    setHashError(null);
    setHashStatus('Вычисляется...');

    try {
      hash = await createHash(message);

      setHash(hash);
      setHashStatus('Вычислен');
    } catch (error) {
      setHashError(error.message);
      setHashStatus('Не вычислен');

      return;
    }

    setSignatureStatus('Создается...');
    try {
      setSignature(await createDetachedSignature(certificate.thumbprint, hash));
      setSignatureStatus('Создана');
    } catch (error) {
      setSignatureError(error.message);
      setSignatureStatus('Не создана');
    }

  }

  return (
    <>
      <Certificate onChange={setCertificate}/>
      <Row gutter={[16, 24]} style={{marginBottom: "34px"}}>
        <Col span={24}>
          <Button
            type="primary"
            onClick={createSignature}
            style={{width: "100%"}}
            disabled={!certificate || !message}>
            Создать подпись
          </Button>
        </Col>
      </Row>
    </>
  );
}
