
const hostName = window.location.hostname;

const apiUrl = (hostName.indexOf('localhost') + 1)
    ? 'http://api.vital.local/v2'
    : `${window.location.protocol}//api.${window.location.hostname}/v2`;

export const mainConfig = {
  companyName: "Витал логистика",
  apiUrl : apiUrl,
  authClient : {
    id: "80ae8d73cf49519ce58a",
    secret: "c720d1ea5733b53cbd30e012ba1d3ae8806e250c2395d3165ebecfb0994705fd83c4ab7e65042006"
  }
};
