import {httpRequest} from "./http-request";

export const deliveriesService = {
  getDeliveries,
  getListNewOrdersDelivery,
  reserveDelivery,
  cancelReservationDelivery,
  getForeignPartnersList,
  createForeignPartner,
  getCountryList,
  refuse,
  changeToLoading,
  returnToLoading,
  archive,
  createDelivery,
  enRoute,
  getListReturnDeliveries,
  getInfoDelivery,
  changeShipperCompany,
  getAddParamOnLoadingDelivery,
  uploadParamsOnLoadingDelivery,
  getDriverPhotos,
  getCarrierOverviewDeliveries,
  getLogistOverviewDeliveries,
  signUpLoadingTime,
  cancelLoadingSignUp,
  getAvailableLoadingTime,
  noteActualLoadTime,
  deleteDriverPhotos,
  returnToEnRoute
};

function parseDeliveriesList(response) {

  const filterFrom = (response.Filter !== null && typeof response.Filter.From !== "undefined")
    ? response.Filter.From
    : [];

  const regionsLoadings = filterFrom.map((itemFrom) => {
    return {
      id: itemFrom.Id,
      title: itemFrom.Name
    };
  });

  const filterTo = (response.Filter !== null && typeof response.Filter.To !== "undefined")
    ? response.Filter.To
    : [];

  const regionsUnloadings = filterTo.map((itemTo) => {
    return {
      id: itemTo.Id,
      title: itemTo.Name
    };
  });

  return {
    deliveries: response.Deliveries,
    /*  regionsLoadings: regionsLoadings,
     regionsUnloadings: regionsUnloadings, */
    permissions: response.Permissions,
    TotalCount: response.TotalCount,
    extData: response.ExtData,
  };
}

/**
 * Получить список рейсов
 */
async function getDeliveries(urlPath, sourceHttpRequestToken, selectedRegionsLoading, selectedRegionsUnloading, selectedSorting, offset = 0, limit = 150, timeout = 5000) {
  return await httpRequest.post(urlPath,
    {
      "from": selectedRegionsLoading,
      "to": selectedRegionsUnloading,
      "sort": selectedSorting,
      "offset": offset,
      "limit": limit,
    },
    {
      cancelToken: sourceHttpRequestToken,
      timeout: timeout
    }).then(
    (response) => {
      if (
        response.status === 200 &&
        typeof response.data.Deliveries !== "undefined" &&
        typeof response.data.Filter !== "undefined"
      ) {
        const deliveriesList = parseDeliveriesList(response.data);
        return Promise.resolve(deliveriesList, response.data.TotalCount);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Получить список новых заказов для утверждения
 */
async function getListNewOrdersDelivery(deliveryId) {
  return await httpRequest.get(`delivery/${deliveryId}/list-new-orders`)
    .then(
      (response) => {

        if (response.status === 200) {
          return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
      });
}

/**
 * Поставить рейс в резерв
 */
async function reserveDelivery(deliveryId) {

  return await httpRequest.post('delivery/reserve', {
    Id: deliveryId,
  })
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Отменить резервирование рейса
 */
async function cancelReservationDelivery(deliveryId) {

  return await httpRequest.post('delivery/cancel-reservation', {
    Id: deliveryId,
  })
    .then((response) => {

      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getForeignPartnersList() {
  return await httpRequest.get('foreign-partner/get-foreign-partners-list', {})
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function createForeignPartner(foreignPartnerData) {
  return await httpRequest.post('foreign-partner/create-foreign-partner', foreignPartnerData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    })
}

async function getCountryList() {
  return await httpRequest.get('system/get-country-list', {})
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Отмена доставки
 * @param id
 * @param reason
 * @returns {Promise<*>}
 */
async function refuse(id, reason) {
  return await httpRequest.post('delivery/refuse-delivery', {
    id: id,
    reason: reason
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    })
}

/**
 * Изменение статуса с AwaitingLoading на Loading
 * @param id
 * @returns {Promise<*>}
 */
async function changeToLoading(id) {
  return await httpRequest.post('delivery/on-loading-delivery', {
    Id: id
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    })
}

/**
 * Вернуть рейс на ожидание погрузки
 */
async function returnToLoading(id, reason) {
  return await httpRequest.post('delivery/return-loading-delivery', {
    id: id,
    reason: reason
  })
    .then((response) => {
      if (response.status === 200 || response.status === 202) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    })
}

/**
 * Вернуть рейс из архива
 * @param id
 * @param reason
 * @returns {Promise<any>}
 */

async function returnToEnRoute(id, reason) {
  return await httpRequest.post('delivery/return-to-en-route', {
    id: id,
    reason: reason
  })
    .then((response) => {
      if (response.status === 200 || response.status === 202) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    })
}
/**
 * Добавление в архив
 * @param id
 * @returns {Promise<*>}
 */
async function archive(id) {
  return await httpRequest.post('delivery/archive-delivery', {
    Id: id
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    })
}

/**
 * Отправить рейс в путь
 * @param id
 * @returns {Promise<*>}
 */
async function enRoute(id) {
  return await httpRequest.post('delivery/en-route-delivery', {
    Id: id
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    })
}


async function createDelivery(deliveryData) {
  return await httpRequest.post('delivery/create-delivery', deliveryData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}


/**
 * Получить данные рейса
 */
async function getInfoDelivery(deliveryId) {
  return await httpRequest.get(`delivery/${deliveryId}`)
    .then(
      (response) => {

        if (response.status === 200) {
          return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
      });
}

/**
 * Получить список обратных рейсов
 */
async function getListReturnDeliveries(deliveryId) {
  return await httpRequest.get(`delivery/get-return-deliveries?deliveryId=${deliveryId}`)
    .then(
      (response) => {

        if (response.status === 200) {
          return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
      });
}

/**
 * Сменить юр.лицо и рц в рейсе
 */
async function changeShipperCompany(deliveryID, shipperCompanyID, distCenterID) {
  return await httpRequest.get(
    `delivery/change-shipper-company?id=${deliveryID}&shipperCompanyID=${shipperCompanyID}&distCenterID=${distCenterID}`
  ).then(
    (response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getAddParamOnLoadingDelivery(deliveryID) {
  return await httpRequest.get(`delivery/get-add-params-on-loading-delivery?id=${deliveryID}`)
    .then(
      (response) => {
        if (response.status === 200) {
          return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
      });
}

async function uploadParamsOnLoadingDelivery(deliveryID, addParams) {
  const formData = new FormData();

  if (addParams) {
    addParams.forEach(value => {
      formData.append(value.name, value.value ? value.value : '');
    });
  }

  return await httpRequest
    .post(`delivery/save-add-params-on-loading-delivery?id=${deliveryID}`, formData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getDriverPhotos(deliveryID) {
  return await httpRequest.get(`delivery/get-driver-photos?id=${deliveryID}`)
    .then(
      (response) => {

        if (response.status === 200) {
          return Promise.resolve(response.data);
        }

        return Promise.reject(httpRequest.getDefaultMessageError());
      });
}

async function getLogistOverviewDeliveries(filters) {
  return await httpRequest.post(`delivery/get-logist-overview-deliveries`, {
    "from": filters.from,
    "to": filters.to,
    "dateLoadingStart": filters.dateLoadingStart === null ? '' : filters.dateLoadingStart,
    "dateLoadingEnd": filters.dateLoadingEnd === null ? '' : filters.dateLoadingEnd,
    "distributionCenters": filters.distributionCenters
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getCarrierOverviewDeliveries(filters) {
  return await httpRequest.post(`delivery/get-carrier-overview-deliveries`, {
    "from": [],
    "to": []
  })
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

// async function getLogistOverviewDeliveries() {
//   return await httpRequest.get(`delivery/get-logist-overview-deliveries`)
//     .then((response) => {
//       if (response.status === 200) {
//         return Promise.resolve(response.data);
//       }
//
//       return Promise.reject(httpRequest.getDefaultMessageError());
//     });
// }
//
// async function getCarrierOverviewDeliveries() {
//   return await httpRequest.get(`delivery/get-carrier-overview-deliveries`)
//     .then((response) => {
//       if (response.status === 200) {
//         return Promise.resolve(response.data);
//       }
//
//       return Promise.reject(httpRequest.getDefaultMessageError());
//     });
// }

async function signUpLoadingTime(id, loadingTime ) {
  return await httpRequest.get(`delivery/sign-up-loading-time?id=${id}&loadingTime=${loadingTime}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function cancelLoadingSignUp(id) {
  return await httpRequest.get(`delivery/cancel-loading-sign-up?id=${id}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getAvailableLoadingTime(id) {
  return await httpRequest.get(`delivery/get-available-loading-time?id=${id}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}
async function noteActualLoadTime(id) {
  return await httpRequest.get(`delivery/note-actual-load-time?id=${id}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function deleteDriverPhotos(id) {
  return await httpRequest.get(`delivery/delete-driver-photos?id=${id}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}
