
import React, {useEffect, useState} from "react";
import "./style.less"
import {Button, notification} from "antd";
import {informServices} from "../../services";

export default function AdsBanner({type}) {
  const [adData, setAdData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const getInfoForUser = () => {
    informServices.getInfo()
      .then((response) => {
        setAdData(response);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(true);
      })
  }


  useEffect(() => {
    if (isLoading) {
      getInfoForUser();
    }
  }, [isLoading])

  const content = () => {
    if (adData !== null) {
      return {__html: adData.text};
    }
  }

  return <>
    {!error && <div className={`ads ${type !== "1" ? 'ads--type-2' : ''} ${type === "3" ? 'ads--top' : ''}`}>
      <a target="_blank" className="ads__link" href={adData !== null ? adData.url : ''}
         aria-label={`перейти по ссылке ${adData !== null ? adData.url : ''}`}/>

      <div className="ads__img">
        {type === "1" &&
          <Button
            className="ads__button"
            type="primary"
            size="small"
            icon="close"
            htmlType="button"
            title="Закрыть"
            style={{border: "none", outline: "none", marginLeft: "auto"}}
            onClick={() => {
            }}
          />
        }

        <img src={adData !== null ? adData.image : ''} width="300" height="200" alt="about ads"/>
      </div>
      <div className="ads__content">
        <div dangerouslySetInnerHTML={content()}/>
        <div className="ads__info">
          <a href={adData !== null ? adData.url : ''}>{adData !== null ? adData.url : ''}</a>
        </div>
      </div>
    </div>
    }
    </>
}
