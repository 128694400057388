/** @var object Статусы пользователей компании ЭДО ЭТрН*/

export const USER_STATUSES = {
  WAITING: "waiting",
  ACTIVE: "active",
  REJECTED: "rejected",
  DELETED: "deleted"
};

/** @var object Наименования статусов пользователей компании ЭДО ЭТрН*/

export const USER_STATUSES_TITLE = {
  [USER_STATUSES.ACTIVE]: 'активные',
  [USER_STATUSES.DELETED]: 'удаленные',
  [USER_STATUSES.REJECTED]: 'отмененные',
  [USER_STATUSES.WAITING]: 'ожидающие обработки',

}

export const USER_ROLES = {
  main: "администратор/владелец",
  signer: "с правом подписи",
  reader: "reader",
  medic: "медик",
  mechanic: "механник",
  driver: "водитель"
}
