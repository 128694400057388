import React, {useEffect, useState} from "react";
import {Button, List, Modal, notification, Tabs, Checkbox, Icon} from "antd";
import {epdAstralService} from "../../../../services";
import {USER_ROLES, USER_STATUSES, USER_STATUSES_TITLE} from "../../../../helpers/epd_astral.helper";

export default function ModalCompanyUsersList({isShow, onCloseModal, setIsNeedUpdate}) {

  const {TabPane} = Tabs;
  const [users, setUsers] = useState(null);
  const [isNeedUpdateUsersList, setIsNeedUpdateUsersList] = useState(true);
  const [userRoles, setUserRoles] = useState([]);

  const statusesArrayData = Object.entries(USER_STATUSES);

  useEffect(() => {
    if (isShow && isNeedUpdateUsersList) {
      const newUsers = {};
      statusesArrayData.forEach((value, index) => {
        epdAstralService.getAbonentRelations(value[1])
          .then((res) => {
            newUsers[value[1]] = res;
            setUsers({...newUsers, [value[1]]: res });
          })
          .catch((err) => {
            notification.error({
              message: `Получение списка: ${USER_STATUSES_TITLE[value[1]]} пользователи компании`,
              description: err,
            });
          });

        if(index === statusesArrayData.length - 1) {
          setIsNeedUpdateUsersList(false);
        }
      })
    }
  }, [isShow, isNeedUpdateUsersList]);

  const approveRelation = (relationGuid, roles) => {
    epdAstralService.approveRelation({relationGuid, roles})
      .then((res) => {
        setIsNeedUpdateUsersList(true);
        setIsNeedUpdate(true);
      })
      .catch((err) => {
        notification.error({
          message: "Подтвержедние запроса на связь", description: err,
        });
      });
  }
  const rejectRelation = (relationId) => {
    epdAstralService.rejectRelation(relationId)
      .then((res) => {
        setIsNeedUpdateUsersList(true);
        setIsNeedUpdate(true);
      })
      .catch((err) => {
        notification.error({
          message: "Отмена запроса на связь", description: err,
        });
      });
  }
  const removeRelation = (relationId) => {
    epdAstralService.removeRelation(relationId)
      .then((res) => {
        setIsNeedUpdateUsersList(true);
        setIsNeedUpdate(true);
      })
      .catch((err) => {
        notification.error({
          message: "Удаление запроса на связь", description: err,
        });
      });
  }

  const roles = Object.entries(USER_ROLES).map((item) => {
    return {label: item[1], value: item[0]}
  });

  const onRoleChecked = (checkedValues) => {
    console.log('checked = ', checkedValues);
    setUserRoles(checkedValues)
  };

  return <Modal
    title={<div>Пользователи компании ЭДО ЭТрН</div>}
    cancelText="Закрыть"
    className="modal-window"
    align={null}
    visible={isShow}
    onCancel={() => {
      onCloseModal(null);
    }}
    forceRender={true}
    footer={<div></div>}
  >
    <Tabs defaultActiveKey={USER_STATUSES.WAITING}
          animated={false} style={{textTransform: "uppercase"}}
    >
      {users !== null && !isNeedUpdateUsersList &&
        statusesArrayData.map((tab) => {
          return <TabPane tab={USER_STATUSES_TITLE[tab[1]]} key={tab[1]}>
            <List itemLayout="horizontal"
                  size="large"
                  bordered={false}
                  dataSource={users[tab[1]]}
                  locale={{emptyText: 'нет пользователей в этом статусе'}}
                  renderItem={(item) => {
                    const buttons = [];
                    if(tab[1] === USER_STATUSES.ACTIVE) {
                      buttons.push(<>
                        <Button
                          type="link" style={{padding: "0"}}
                          onClick={() => rejectRelation(item.relation_id)}
                        >Отменить</Button>
                        <Button
                          type="link" style={{padding: "0", marginLeft: "10px"}}
                          onClick={() => removeRelation(item.relation_id)}
                        >Удалить</Button>
                      </>)
                    }
                    if(tab[1] === USER_STATUSES.WAITING) {
                      buttons.push(<>
                        <Button
                          type="link" style={{padding: "0"}}
                          onClick={() => approveRelation(item.relation_id, userRoles.toString())}
                        >Одобрить</Button>
                        <Button
                          type="link" style={{padding: "0", marginLeft: "10px"}}
                          onClick={() => removeRelation(item.relation_id)}
                        >Отклонить</Button>
                      </>)
                    }
                    return (
                      <List.Item
                        key={item.relation_id}
                        actions={buttons}
                        style={{marginTop: "20px"}}
                      >
                        <List.Item.Meta
                          title={`ИНН: ${item.user_inn}`}
                          description={<> 
                            <div>
                            <span>ФИО: {item.profileInfo.fullName.surname} {item.profileInfo.fullName.name} {item.profileInfo.fullName.patronymic}</span>
                            </div>
                            <span>Роли пользователя:</span>
                            {(item.roles !== null && item.roles.length) && <ul>
                            {item.roles.map((val)=>{
                              return <li> - {USER_ROLES[val]}</li>
                            })}
                            </ul>}
                            </>
                          }
                        />
                        {tab[1] === USER_STATUSES.WAITING &&
                          <Checkbox.Group options={roles} onChange={onRoleChecked} checked={userRoles}/>
                        }
                      </List.Item>)
                  }}
            >
            </List>
          </TabPane>
        })
      }
    </Tabs>
  </Modal>
}
