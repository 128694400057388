import {httpRequest} from "./http-request";

export const epdAstralService = {
  registerApiUser,
  loginApiUser,
  registerApiCompany,
  loginApiCompany,
  getInfoApiUser,
  getProfile,
  updateProfile,
  getAvailableCompanies,
  getBase64Auth,
  getAbonentRelations,
  approveRelation,
  rejectRelation,
  removeRelation,
  selectApiUser,
  joinCompany,
};

/**
 *
 * @param username
 * @param password
 * @returns {Promise<any>}
 */
async function registerApiUser({username, password}) {
  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);

  return await httpRequest
    .post('etrn/register-api-user', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function registerApiCompany({username, password, legal_entity_id, sign}) {
  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  formData.append("legal_entity_id", legal_entity_id);
  formData.append("sign", sign);

  return await httpRequest
    .post('etrn/register-api-company', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Получение информации для текущего пользователя о всех привязанных апи пользователях и компаниях *
 */
async function getInfoApiUser() {
  return await httpRequest.get('etrn/get-info-api').then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

/**
 *
 * @returns {Promise<any>}
 */
async function getProfile() {
  return await httpRequest.get('etrn/get-profile').then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

/**
 * Логин в системе ETRN
 * @param username
 * @param password
 * @returns {Promise<any>}
 */
async function loginApiUser({username, password}) {
  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);

  return await httpRequest
    .post('etrn/login-api-user', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Логин от имени компании
 * @param username
 * @param password
 * @param sign
 * @returns {Promise<any>}
 */
async function loginApiCompany({username, password, sign}) {
  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);
  formData.append("sign", sign);

  return await httpRequest
    .post('etrn/login-api-company', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Обновление данных профиля апи пользователя
 * @param inn
 * @param snils
 * @param phone
 * @param name
 * @param surname
 * @param pathronymic
 * @returns {Promise<any>}
 */
async function updateProfile({inn, snils, phone, name, surname, patronymic}) {
  const formData = new FormData();
  formData.append("inn", inn);
  formData.append("snils", snils);
  formData.append("phone", phone);
  formData.append("name", name);
  formData.append("surname", surname);
  formData.append("patronymic", patronymic);

  return await httpRequest
    .post('etrn/update-profile', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Запрос на присоединение к компании
 * @param abonentId
 * @returns {Promise<any>}
 */
async function joinCompany({abonentId}) {
  const formData = new FormData();
  formData.append("abonentId", abonentId);

  return await httpRequest
    .post('etrn/join-company', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Получение списка зарегистрированных компаний Etrn API
 * @returns {Promise<any>}
 */
async function getAvailableCompanies() {
  return await httpRequest
    .get('etrn/get-available-companies')
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Получить связи с пользователями для компании
 * @param status
 * @returns {Promise<any>}
 */
async function getAbonentRelations(status = "active") {
  const param = status
  && ["waiting", "rejected", "active", "deleted"].some((item) => item === status)
    ? `?status=${status}` : '';

  return await httpRequest
    .get(`etrn/get-abonent-relations${param}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Подтвержедние запроса на связь
 * @param relationGuid
 * @param roles
 * @returns {Promise<any>}
 */
async function approveRelation({relationGuid, roles}) {
  const formData = new FormData();
  formData.append("relationGuid", relationGuid);
  formData.append("roles", roles);

  return await httpRequest
    .post('etrn/approve-relation', formData, {
        headers: {
          "Content-Type": "application/x-bittorrent",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Отмена запроса на связь
 * @param relationGuid
 * @returns {Promise<any>}
 */
async function rejectRelation(relationGuid) {
  const formData = new FormData();
  formData.append("relationGuid", relationGuid);

  return await httpRequest
    .post('etrn/reject-relation', formData, {
        headers: {
          "Content-Type": "application/x-bittorrent",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Удаление запроса на связь
 * @param relationGuid
 * @returns {Promise<any>}
 */
async function removeRelation(relationGuid) {
  const formData = new FormData();
  formData.append("relationGuid", relationGuid);

  return await httpRequest
    .post('etrn/remove-relation', formData, {
        headers: {
          "Content-Type": "application/x-bittorrent",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Выбор активного апи пользователя и активной компании
 * @param etrnUserId
 * @param etrnCompanyId
 * @returns {Promise<any>}
 */
async function selectApiUser({etrnUserId, etrnCompanyId}) {
  const formData = new FormData();
  formData.append("etrnUserId", etrnUserId);
  if (etrnCompanyId) {
    formData.append("etrnCompanyId", etrnCompanyId);
  }

  return await httpRequest
    .post('etrn/select-api-user', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Получить токен для создания откреплённой подписи
 * @param username
 * @param password
 * @returns {Promise<any>}
 */
async function getBase64Auth({username, password}) {
  const formData = new FormData();
  formData.append("username", username);
  formData.append("password", password);

  return await httpRequest
    .post('etrn/get-base64-auth', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}
