import React from "react";
import {Form, Icon, Input, Button, Row, Col, notification, Select, Checkbox, Modal} from "antd";

//import "antd/lib/button/style/index.less";
import {Redirect} from "react-router-dom";
import {AppContext} from "../../../contexts/app-context";
import Spinner from "../../spinner";
// import GoogleAndroidAppLink from "../../google-android-app-link";
import {userServices} from "../../../services";
import ShowTerms from "../../modals/show-terms";
import Banner from "../../banner";
import ShowOffer from "../../modals/show-offer";
import ShowTermsOfUse from "../../modals/terms-of-use";

class RegisterPage extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      successful: false,
      fieldsErrors: [],
      redirectToLogin: false,
      history: null,
      isShowModalTerms: false,
      isShowModalTermsOfUse: false,
      isShowModalOffer: false,
      isTermsAgreed: false,
      isTermsOfUseAgreed: false,
      isOfferAgreed: false,
      isButtonEnabled: false,
      message: '',

      registerCountry: '1',
      phoneCode: '+7'
    };

    this.buttonStyle = {
      width: "100%",
      fontSize: "20px",
      height: "40px",
      backgroundColor: "#1CAA53",
      borderRadius: "7px"
    };
  }

  onSubmitFormRegister = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });

        let countryId = this.state.registerCountry;
        let phone = values.phone;

        userServices
          .register({
            fio: values.fio,
            email: values.email,
            phone: phone,
            countryId: countryId,
            legalNumber: values.legalNumber
          })
          .then((data) => {
            if (data.status === 'success') {
              this.setState({
                successful: true,
                message: data.message,
              });
            } else {
              this.setState({
                fieldsErrors: data.errors
              });

              notification.warning({
                message: "Регистрация",
                description: data.message.toString(),
              });
            }
          })
          .catch((errorMsg) => {
            notification.warning({
              message: "Регистрация",
              description: errorMsg.toString(),
            });
          })
          .finally(() => {
            this.setState({
              loading: false,
            });
          });
      }
    });
  };

  loginBtnAction = () => {
    this.setState({
      redirectToLogin: true,
    });
  };

  render() {
    this.user = userServices.getUser();

    if (this.user.loggedIn) {
      return <Redirect to="/"/>;
    }

    if (this.state.redirectToLogin) {
      return <Redirect to="/login"/>;
    }

    if (this.context.filter.enable) {
      this.context.disableFilter();
    }

    const {getFieldDecorator} = this.props.form;

    const getFieldStatus = fieldName => {
      return (this.state.fieldsErrors && typeof this.state.fieldsErrors[fieldName] !== "undefined")
        ? 'error'
        : 'success';
    };

    const getFieldHelp = fieldName => {
      return (this.state.fieldsErrors && typeof this.state.fieldsErrors[fieldName] !== "undefined")
        ? this.state.fieldsErrors[fieldName][0]
        : '';
    };

    const setCountryPhone = (value, data) => {
      this.state.phoneCode = value;
      this.state.registerCountry = data.key;
    };

    const setButtonEnabled = () => {
      const vals = this.props.form.getFieldsValue(['fio', 'email', 'phone']);
      if (!Boolean(Object.values(vals).filter((item) => item === undefined).length)) {
        this.setState({
          isButtonEnabled: true,
        });
      } else {
        this.setState({
          isButtonEnabled: false,
        });
      }
    };

    // оферта
    const setIsShowOffer = (val) => {
      this.setState({
        isShowModalOffer: val,
      });
      setButtonEnabled();
    }

    const setIsOfferAgreed = (val) => {
      this.setState({
        isOfferAgreed: val,
      });
      setButtonEnabled();
    }

    const handleLinkClick = (evt) => {
      evt.preventDefault();
      setIsShowOffer(true);
    }

    const handleOfferCheckboxChange = () => {
      setIsOfferAgreed(!this.state.isOfferAgreed);
      setButtonEnabled();
    };


    // пользовательское соглашение
    const setIsShowTermsOfUse = (val) => {
      this.setState({
        isShowModalTermsOfUse: val,
      });
      setButtonEnabled();
    }
    const setIsTermsOfUseAgreed = (val) => {
      this.setState({
        isTermsOfUseAgreed: val,
      });
      setButtonEnabled();
    }
    const handleTermsOfUseLinkClick = (evt) => {
      evt.preventDefault();
      setIsShowTermsOfUse(true);
    }
    const handleTermsOfUseCheckboxChange = () => {
      setIsTermsOfUseAgreed(!this.state.isTermsOfUseAgreed);
      setButtonEnabled();
    };

    // политика обработки
    const setIsShowTerms = (val) => {
      this.setState({
        isShowModalTerms: val,
      });
      setButtonEnabled();
    }

    const setIsTermsAgreed = (val) => {
      this.setState({
        isTermsAgreed: val,
      });
      setButtonEnabled();
    }

    const handleCheckboxChange = () => {
      setIsTermsAgreed(!this.state.isTermsAgreed);
      setButtonEnabled();
    };

    const handleTermsLinkClick = (evt) => {
      evt.preventDefault();
      setIsShowTerms(true);
    }

    return (
      <Spinner loading={this.state.loading} label="Подождите">
        <Row>
          <Col span={12} offset={6} className="register-page-content">
            <h1>{this.state.successful ? "Регистрация успешна" : "Регистрация"}</h1>
            {this.state.successful ? (
              <>
                <div className="register-success">{this.state.message}
                </div>
                <Button style={this.buttonStyle} type="primary" htmlType="submit" className="login-form-button"
                        onClick={() => this.loginBtnAction()}>
                  Войти
                </Button>
              </>
            ) : (
              <Form onSubmit={this.onSubmitFormRegister} className="register-form">
                <Form.Item
                  validateStatus={getFieldStatus('fio')}
                  help={getFieldHelp('fio')}
                >
                  {getFieldDecorator("fio", {
                    rules: [{required: true, message: "Заполните ФИО"}],
                    initialValue: this.state.username,
                  })(<Input size="large" prefix={<Icon type="user" style={{color: "rgba(0,0,0,.25)"}}/>}
                            onBlur={setButtonEnabled} placeholder="ФИО"/>)}
                </Form.Item>
                <Form.Item
                  validateStatus={getFieldStatus('email')}
                  help={getFieldHelp('email')}
                >
                  {getFieldDecorator("email", {
                    rules: [{required: true, message: "Заполните email"}],
                    initialValue: this.state.username,
                  })(<Input size="large" onBlur={setButtonEnabled}
                            prefix={<Icon type="mail" style={{color: "rgba(0,0,0,.25)"}}/>} placeholder="Email"/>)}
                </Form.Item>

                <Select defaultValue={'+7'} onChange={setCountryPhone} size="large" style={{
                  width: "14%",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "13px",
                  lineHeight: "12px",
                  color: "black"
                }}>
                  <Select.Option key={1}>+7<img src="https://dota2.ru/img/flags/ru.png" alt="РФ"/></Select.Option>
                  <Select.Option key={3}>+375 <img src="https://dota2.ru/img/flags/by.png" alt="РБ"/></Select.Option>
                  <Select.Option key={6}>+7<img src="https://dota2.ru/img/flags/kz.png" alt="КЗ"/></Select.Option>
                </Select>

                <Form.Item
                  validateStatus={getFieldStatus('phone')}
                  help={getFieldHelp('phone')}
                  style={{width: "86%", float: "right"}}
                >
                  {getFieldDecorator("phone", {
                    rules: [{required: true, message: "Заполните телефон"}],
                  })(
                    <Input size="large" prefix={<Icon type="phone" style={{color: "rgba(0,0,0,.25)"}}/>}
                           onBlur={setButtonEnabled} placeholder="Телефон (без кода страны)"/>
                  )}
                </Form.Item>

                <Form.Item
                  validateStatus={getFieldStatus('legalNumber')}
                  help={getFieldHelp('legalNumber')}
                >
                  {getFieldDecorator("legalNumber", {
                    rules: [{required: true, message: "Заполните ИНН/УНП/ИИН/БИН"}],
                    initialValue: this.state.legalNumber,
                  })(<Input size="large" onBlur={setButtonEnabled}
                            prefix={<Icon type="solution" style={{color: "rgba(0,0,0,.25)"}}/>}
                            placeholder="ИНН/УНП/ИИН/БИН"/>)}
                </Form.Item>

                <Form.Item
                  validateStatus={getFieldStatus('readTermsOfUse')}
                  help={getFieldHelp('readTermsOfUse')}
                >
                  {getFieldDecorator("readTermsOfUse", {
                    rules: [{required: true, message: ""}],
                    initialValue: this.state.isTermsAgreed
                  })(
                    <Checkbox size="large"
                              onChange={handleCheckboxChange}
                              checked={this.state.isTermsAgreed}
                              className="register-form-agree"
                    >
                      Я прочитал и принимаю
                      <a href="#" onClick={handleTermsLinkClick}
                         className="link register-form-terms-link">
                        политику в отношении обработки персональных данных
                      </a>
                    </Checkbox>
                  )}
                </Form.Item>
                <Form.Item
                  validateStatus={getFieldStatus('readOffer')}
                  help={getFieldHelp('readOffer')}
                >
                  {getFieldDecorator("readOffer", {
                    rules: [{required: true, message: ""}],
                    initialValue: this.state.isOfferAgreed
                  })(
                    <Checkbox size="large"
                              onChange={handleOfferCheckboxChange}
                              checked={this.state.isOfferAgreed}
                              className="register-form-agree"
                    >
                      Я принимаю
                      <a href="" onClick={handleLinkClick}
                         className="link register-form-terms-link">
                        публичную оферту о согласии с обработкой персональных данных
                      </a>
                    </Checkbox>
                  )}
                </Form.Item>
                <Form.Item
                  validateStatus={getFieldStatus('readTermsOfUse1')}
                  help={getFieldHelp('readTermsOfUse1')}
                >
                  {getFieldDecorator("readTermsOfUse1", {
                    rules: [{required: true, message: ""}],
                    initialValue: this.state.isTermsOfUseAgreed
                  })(
                    <Checkbox size="large"
                              onChange={handleTermsOfUseCheckboxChange}
                              checked={this.state.isTermsOfUseAgreed}
                              className="register-form-agree"
                    >
                      Я принимаю
                      <a href="" onClick={handleTermsOfUseLinkClick}
                         className="link register-form-terms-link">
                        пользовательское соглашение
                      </a>
                    </Checkbox>
                  )}
                </Form.Item>
                <Form.Item>
                  <Button type="primary" style={this.buttonStyle}
                          disabled={!this.state.isButtonEnabled || !this.state.isTermsAgreed || !this.state.isOfferAgreed || !this.state.isTermsOfUseAgreed}
                          htmlType="submit" className="register-form-button">
                    Зарегистрироваться
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Col>
          <Col xs={24} lg={12}>
            {/*<GoogleAndroidAppLink />*/}
            <Banner banner={1}/>
          </Col>
          <Col xs={24} lg={12}>
            <Banner banner={2}/>
          </Col>
        </Row>
        <ShowTerms setIsShow={setIsShowTerms} setIsTermAgreed={setIsTermsAgreed} isShow={this.state.isShowModalTerms}/>
        <ShowOffer setIsShow={setIsShowOffer} setIsOfferAgreed={setIsOfferAgreed} isShow={this.state.isShowModalOffer}/>
        <ShowTermsOfUse setIsShow={setIsShowTermsOfUse} setIsTermOfUseAgreed={setIsTermsOfUseAgreed}
                        isShow={this.state.isShowModalTermsOfUse}/>
      </Spinner>
    );
  }
}

export default Form.create({name: "normal_register"})(RegisterPage);
