import React, {useState, useEffect, useContext} from "react";
import {Icon, Layout, Menu, notification} from "antd";
import {UserOutlined, SolutionOutlined} from "@ant-design/icons";
import {Link, Route, Redirect} from "react-router-dom";

import {AppContext} from "../../../contexts/app-context";
import {consignorService, userServices} from "../../../services";
import Error from "../../error";
import {useFetchDeliveriesList} from "../../../hooks/use-fetch-deliveries-list";
import Spinner from "../../../components/spinner";
import NotificationMessage from "../../../components/modals/notification-message";

import {
  CONSIGNOR_DIRECTOR_PERMISSION, LAWYER_LEGAL_ENTITY_APPROVE,
  ACCOUNTANT_LEGAL_ENTITY_APPROVE, LOGIST_LEGAL_ENTITY_APPROVE,
  PERSONAL_RESPONSIBILITY_LEGAL_ENTITY_APPROVE, VIEW_FREE_DELIVERIES_LOGIST, VIEW_COMPANY_PAGE
} from "../../../helpers/user_permission.helper";
import ShippersCompanies from "./shippers-companies";
import StaffUsers from "./staff-users";
import Orders from "./orders";
import DistributionCentres from './distribution-centres';
import CreateDelivery from './create-delivery';
import OrdersToConsignor from "./approve-orders";
import ShipperCompanySettings from "./shipper-company-settings";
import UserNotification from "./user-notification";
import UserNotificationSettings from "./user-notification-settings";
import CompaniesList from "./companies-list";
import UserProfileEditForm from "../../user-profile-edit-form";
import ActionsToApprove from "./actions-to-approve";


function LeftMenu({collapsed, menuElementsData}) {
  if (menuElementsData == null) {
    menuElementsData = [];
  }
  const menuElements = menuElementsData.map(menuItemData => {
    if (menuItemData.subMenu) {
      const groupItems = menuItemData.items.map(item => {
        return (
          <Menu.Item key={item.key}>
            <Link to={item.link}>{item.label}</Link>
          </Menu.Item>
        );
      });

      return (
        <Menu.SubMenu key={menuItemData.parentKey} title={menuItemData.label}>
          {groupItems}
        </Menu.SubMenu>
      );
    } else {
      return (
        <Menu.Item key={menuItemData.parentKey}>
          <Link to={menuItemData.link}>{menuItemData.label}</Link>
        </Menu.Item>
      );
    }
  });

  const menuSelectedItem = menuElementsData
    .filter(data => {
      if (data.subMenu) {
        const itemsLinks = data.items.filter(dataItem => {
          return dataItem.link === window.location.pathname;
        });

        return itemsLinks.length;
      } else {
        return data.link === window.location.pathname;
      }
    })
    .map(item => {
      if (item.subMenu) {
        const itemsLinks = item.items.filter(dataItem => {
          return dataItem.link === window.location.pathname;
        });

        return {parentKey: item.parentKey, itemKey: itemsLinks[0].key};
      } else {
        return {parentKey: item.parentKey, itemKey: item.parentKey};
      }
    })
    .shift();

  return (
    <Menu
      defaultSelectedKeys={typeof menuSelectedItem !== "undefined" ? [menuSelectedItem.itemKey] : []}
      defaultOpenKeys={typeof menuSelectedItem !== "undefined" ? [menuSelectedItem.parentKey] : []}
      mode="inline"
      theme="light"
    >
      {menuElements}
    </Menu>
  );
}

export default function ConsignorPage() {
  const user = userServices.getUser();

  if (user.isGuest()) {
    return <Error status={403} desc="Страница не доступна"/>;
  }

  const [{setNeedUpdate, setStopListUpdate, notifications}] = useFetchDeliveriesList("", true, false, false, true);

  const menuElements = () => {
    if (consignorSettings.isVital) {
      if (user.isAccessTo(CONSIGNOR_DIRECTOR_PERMISSION)) {
        return [
          {
            parentKey: "group1-1",
            link: "/consignor/staff-users",
            label: (
              <span>
                <UserOutlined/>
                <span>Сотрудники</span>
              </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-2",
            link: "/consignor/shippers-companies",
            label: (
              <span>
                <SolutionOutlined/>
                <span>Юридические лица</span>
              </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-4",
            link: "/consignor/distribution-centres",
            label: (
              <span>
                <SolutionOutlined/>
                <span>Распределительные центры</span>
              </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-3",
            link: "/consignor/orders",
            label: (
              <span>
                <SolutionOutlined/>
                <span>Заключение договоров</span>
              </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-7",
            link: "/consignor/orders-to-consignor",
            label: (
              <span>
                   <SolutionOutlined/>
                   <span>Согласование Юр.лиц</span>
                </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-5",
            link: "/consignor/create-delivery",
            label: (
              <span>
                <SolutionOutlined/>
                <span>Создать рейс</span>
              </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-8",
            link: "/consignor/user-notification",
            label: (
              <span>
                <SolutionOutlined/>
                <span>Уведомления</span>
              </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-11",
            link: "/consignor/actions-to-approve-list",
            label: (
              <span>
                        <SolutionOutlined/>
                        <span>Запросы на подтверждение</span>
                    </span>
            ),
          },
          {
            parentKey: "group1-9",
            link: "/consignor/companies-list",
            label: (
              <span>
                        <SolutionOutlined/>
                        <span>Компании перевозчиков</span>
                    </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-10",
            link: "/consignor/user-edit-profile",
            label: (
              <span>
                  <UserOutlined/>
                  <span>Профиль</span>
              </span>
            ),
            permission: VIEW_COMPANY_PAGE,
          }
        ];
      } else if (user.isAccessTo(LOGIST_LEGAL_ENTITY_APPROVE)) {
        return [
          {
            parentKey: "group1",
            link: null,
            label: (
              <span>
              <SolutionOutlined/>
              <span>Заявки</span>
            </span>
            ),
            subMenu: true,
            items: [
              {
                key: '1',
                link: '/consignor/orders-to-consignor',
                label: 'Заявки от Юр.лиц',
              },
              {
                key: '2',
                link: '/consignor/orders',
                label: 'Заключение договоров',
              }
            ]
          },
          {
            parentKey: "group2",
            link: "/consignor/create-delivery",
            label: (
              <span>
              <SolutionOutlined/>
              <span>Создать рейс</span>
            </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-8",
            link: "/consignor/user-notification",
            label: (
              <span>
                <SolutionOutlined/>
                <span>Уведомления</span>
              </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-9",
            link: "/consignor/companies-list",
            label: (
              <span>
                        <SolutionOutlined/>
                        <span>Компании перевозчиков</span>
                    </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-10",
            link: "/consignor/user-edit-profile",
            label: (
              <span>
                  <UserOutlined/>
                  <span>Профиль</span>
              </span>
            ),
            permission: VIEW_COMPANY_PAGE,
          }
        ];
      } else {
        return [
          {
            parentKey: "group1",
            link: "/consignor/user-notification",
            label: (
              <span>
                        <SolutionOutlined/>
                        <span>Уведомления</span>
                      </span>
            ),
            subMenu: false,
            items: []
          }
        ];
      }
    } else {
      if (user.isAccessTo(CONSIGNOR_DIRECTOR_PERMISSION)) {
        return [
          {
            parentKey: "group1",
            link: "/consignor/staff-users",
            label: (
              <span>
                <UserOutlined/>
                <span>Сотрудники</span>
              </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group2",
            link: "/consignor/shippers-companies",
            label: (
              <span>
                <SolutionOutlined/>
                <span>Юридические лица</span>
              </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group4",
            link: "/consignor/distribution-centres",
            label: (
              <span>
                <SolutionOutlined/>
                <span>Распределительные центры</span>
              </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group5",
            link: "/consignor/create-delivery",
            label: (
              <span>
                <SolutionOutlined/>
                <span>Создать рейс</span>
              </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group6",
            link: "/consignor/orders",
            label: (
              <span>
                       <SolutionOutlined/>
                       <span>Заключение договоров</span>
                    </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group7",
            link: "/consignor/orders-to-consignor",
            label: (
              <span>
                       <SolutionOutlined/>
                       <span>Согласование Юр.лиц</span>
                    </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-8",
            link: "/consignor/user-notification",
            label: (
              <span>
                <SolutionOutlined/>
                <span>Уведомления</span>
              </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-9",
            link: "/consignor/companies-list",
            label: (
              <span>
                        <SolutionOutlined/>
                        <span>Компании перевозчиков</span>
                    </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-10",
            link: "/consignor/user-edit-profile",
            label: (
              <span>
                  <UserOutlined/>
                  <span>Профиль</span>
              </span>
            ),
            permission: VIEW_COMPANY_PAGE,
          },
        ];
      } else if (user.isAccessTo(LOGIST_LEGAL_ENTITY_APPROVE)) {
        return [
          {
            parentKey: "group6",
            link: "/consignor/orders",
            label: (
              <span>
                       <SolutionOutlined/>
                       <span>Заключение договоров</span>
                    </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group7",
            link: "/consignor/orders-to-consignor",
            label: (
              <span>
                       <SolutionOutlined/>
                       <span>Согласование Юр.лиц</span>
                    </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-8",
            link: "/consignor/user-notification",
            label: (
              <span>
                        <SolutionOutlined/>
                        <span>Уведомления</span>
                    </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-9",
            link: "/consignor/companies-list",
            label: (
              <span>
                        <SolutionOutlined/>
                        <span>Компании перевозчиков</span>
                    </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-10",
            link: "/consignor/user-edit-profile",
            label: (
              <span>
                  <UserOutlined/>
                  <span>Профиль</span>
              </span>
            ),
            permission: VIEW_COMPANY_PAGE,
          },
        ];
      } else if (
        user.isAccessTo(LAWYER_LEGAL_ENTITY_APPROVE) ||
        user.isAccessTo(ACCOUNTANT_LEGAL_ENTITY_APPROVE)
      ) {
        return [
          {
            parentKey: "group7",
            link: "/consignor/orders-to-consignor",
            label: (
              <span>
                 <SolutionOutlined/>
                 <span>Согласование Юр.лиц</span>
              </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-8",
            link: "/consignor/user-notification",
            label: (
              <span>
                <SolutionOutlined/>
                <span>Уведомления</span>
              </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-10",
            link: "/consignor/user-edit-profile",
            label: (
              <span>
                  <UserOutlined/>
                  <span>Профиль</span>
              </span>
            ),
            permission: VIEW_COMPANY_PAGE,
          }
        ];
      } else {
        return [
          {
            parentKey: "group1",
            link: "/consignor/user-notification",
            label: (
              <span>
                        <SolutionOutlined/>
                        <span>Уведомления</span>
                      </span>
            ),
            subMenu: false,
            items: []
          },
          {
            parentKey: "group1-10",
            link: "/consignor/user-edit-profile",
            label: (
              <span>
                  <UserOutlined/>
                  <span>Профиль</span>
              </span>
            ),
            permission: VIEW_COMPANY_PAGE,
          }
        ];
      }
    }
  };


  const [collapsed, setCollapsed] = useState(false);
  const appContext = useContext(AppContext);

  const [loading, setLoading] = useState(true);

  const [consignorSettings, setConsignorSettings] = useState([]);

  const handleResize = () => {
    if (collapsed === false && window.innerWidth < 1000) {
      setCollapsed(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (loading) {
      consignorService.getSettings()
        .then(response => {
          setConsignorSettings(response);

          setLoading(false);
        })
        .catch(err => {
          notification.error(
            {
              message: 'Получение настроек грузоотправителя',
              description: err.message
            }
          );
        })
    }
  });

  handleResize();

  if (appContext.filter.enable) {
    appContext.disableFilter();
  }

  if (
    !(
      user.isAccessTo(CONSIGNOR_DIRECTOR_PERMISSION)
      || user.isAccessTo(VIEW_FREE_DELIVERIES_LOGIST)
      || user.isAccessTo(PERSONAL_RESPONSIBILITY_LEGAL_ENTITY_APPROVE)
      || user.isAccessTo(LAWYER_LEGAL_ENTITY_APPROVE)
      || user.isAccessTo(LOGIST_LEGAL_ENTITY_APPROVE)
      || user.isAccessTo(ACCOUNTANT_LEGAL_ENTITY_APPROVE)
    )
  ) {
    return <Error status={403} desc="Страница не доступна"/>;
  }

  if (window.location.pathname === "/consignor") {
    if (user.isAccessTo(CONSIGNOR_DIRECTOR_PERMISSION)) {
      return <Redirect to="/consignor/staff-users"/>;
    }

    if (
      user.isAccessTo(PERSONAL_RESPONSIBILITY_LEGAL_ENTITY_APPROVE)
      || user.isAccessTo(LAWYER_LEGAL_ENTITY_APPROVE)
      || user.isAccessTo(LOGIST_LEGAL_ENTITY_APPROVE)
      || user.isAccessTo(ACCOUNTANT_LEGAL_ENTITY_APPROVE)
    ) {
      return <Redirect to="/consignor/orders-to-consignor"/>;
    }

    return <Redirect to="/consignor/user-notification"/>;
  }

  if (loading) {
    return <Spinner loading={loading}/>;
  }

  return (
    <Layout
      tagName="section"
      style={{
        padding: "25px 0",
        background: "#fff",
        borderRadius: "11px",
        boxShadow: "0px 4px 10px rgba(130, 137, 147, 0.2)"
      }}
    >
      <NotificationMessage notifications={notifications} setNeedUpdate={setNeedUpdate}
                           setStopListUpdate={setStopListUpdate}/>
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        theme="light"
        width={293}>
        <Icon
          className="trigger"
          onClick={() => {
            setCollapsed(!collapsed)
          }}
          type={collapsed ? 'menu-unfold' : 'menu-fold'}
          style={{marginLeft: "24px", color: "#1CAA53", fontSize: "32px", lineHeight: "60px"}}
        />
        <LeftMenu collapsed={collapsed} menuElementsData={menuElements()}/>
      </Layout.Sider>
      <Layout.Content style={{padding: "0 24px", minHeight: 350}} className="table-window">
        <Route path="/consignor/staff-users" exact component={StaffUsers}/>
        <Route path="/consignor/shippers-companies"
               render={(props) => <ShippersCompanies consignorSettings={consignorSettings} {...props} />}/>
        <Route path="/consignor/orders" render={(props) => <Orders consignorSettings={consignorSettings} {...props}/>}/>
        <Route path="/consignor/orders-to-consignor" exact component={OrdersToConsignor}/>
        <Route path="/consignor/distribution-centres"
               render={(props) => <DistributionCentres consignorSettings={consignorSettings} {...props} />}/>
        <Route path="/consignor/create-delivery" exact component={CreateDelivery}/>
        <Route path="/consignor/user-notification" exact component={UserNotification}/>
        <Route path="/consignor/user-notification-settings" exact component={UserNotificationSettings}/>
        <Route path="/consignor/shipper-company-settings" exact component={ShipperCompanySettings}/>
        <Route path="/consignor/companies-list"
               render={(props) => <CompaniesList consignorSettings={consignorSettings} {...props} />}/>
        <Route path="/consignor/user-edit-profile" exact component={UserProfileEditForm}/>
        <Route path="/consignor/actions-to-approve-list" exact component={ActionsToApprove}/>
      </Layout.Content>
    </Layout>
  );
}
