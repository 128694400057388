import {httpRequest} from "./http-request";

export const approveOrderService = {
  getAdditionalParamsOnContractCreate,
  getContractsTypes,
  getContractsListOnUpload,
  uploadContractsToShipperCompanyOrder,
  agreeContractOrder,
  getAddParamsOnApproveLegalEntity,
  getDownloadContractOrderFiles,
  getDeleteContractOrder,
  getArchiveContractOrder,
  getContractOrderInfo,
  fullRefuseApproveOrder,
  deleteContractOrder
};

async function getAdditionalParamsOnContractCreate(legalEntityId, shipperCompanyId) {
  return await httpRequest.get(`approve-order/get-add-params-list-for-contract-create?LegalEntityId=` + legalEntityId + `&ShipperCompanyId=` + shipperCompanyId).then((response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    }

    return Promise.reject(httpRequest.getDefaultMessageError());
  });
}

async function getContractsTypes(legalEntityID, shipperCompanyID) {
  return await httpRequest.get(`approve-order/get-contract-types-list-on-contract-create?legalEntityID=${legalEntityID}&shipperCompanyID=${shipperCompanyID}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getContractsListOnUpload(orderID) {
  return await httpRequest.get(`approve-order/get-docs-list-on-upload-filled-contracts?orderID=${orderID}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function uploadContractsToShipperCompanyOrder(orderID, contracts) {
  const formData = new FormData();
  formData.append('orderID', orderID);

  for (let contract of contracts) {
    if (!contract.filledFile) {
      continue;
    }
    formData.append(contract.name, contract.filledFile);
  }

  return await httpRequest.post(`approve-order/upload-contracts-to-shipper-company-order`, formData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function agreeContractOrder(orderId, contracts, isNeedContracts = 1) {
  const formData = new FormData();
  formData.append('OrderId', orderId);
  formData.append('IsNeedContracts', isNeedContracts);

  for (let contract of contracts) {
    formData.append(contract.name, contract.filledFile);
  }

  return await httpRequest
    .post(`approve-order/agree-order-to-shipper-company`, formData)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getAddParamsOnApproveLegalEntity(orderID, approveType) {
  return await httpRequest.get(`approve-order/get-add-params-on-approve?orderID=${orderID}&approveType=${approveType}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getDownloadContractOrderFiles(id) {
  return await httpRequest.get(`approve-order/download-contract-order-files?id=${id}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getArchiveContractOrder(id) {
  return await httpRequest.get(`approve-order/archive-contract-order?id=${id}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getContractOrderInfo(id) {
  return await httpRequest.get(`approve-order/get-contract-order-info?id=${id}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

async function getDeleteContractOrder(id) {
  return await httpRequest.get(`approve-order/delete-contract-order?id=${id}`)
    .then((response) => {
      if (response.status === 200) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Полная отмена подтвержденного согласования
 * @param id
 * @param  reason
 * @returns {Promise<any>}
 */

async function fullRefuseApproveOrder(id, reason) {
  return await httpRequest
    .post(`approve-order/full-refuse-approve-order`, {
      "id" : id,
      "reason": reason
    } )
    .then((response) => {
      if (response.status === 200 || response.status === 202) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}

/**
 * Удалить договор
 */

async function deleteContractOrder(id, reason) {
  return await httpRequest
    .get(`approve-order/delete-contract-order?id=${id}&reason=${reason}`)
    .then((response) => {
      if (response.status === 200 || response.status === 202) {
        return Promise.resolve(response.data);
      }

      return Promise.reject(httpRequest.getDefaultMessageError());
    });
}
