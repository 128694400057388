import {
  getSystemInfo,
  createDetachedSignature,
  isValidSystemSetup,
  getUserCertificates,
  execute,
  getCertificate
} from 'crypto-pro';
import {useEffect, useState} from "react";


export const useCryptoPro = () => {
  const [systemInfo, setSystemInfo] = useState(null);
  const [systemInfoError, setSystemInfoError] = useState(null);
  const [certificates, setCertificates] = useState([]);
  const [certificatesError, setCertificatesError] = useState([]);
  const [certificate, setCertificate] = useState(null);
  const [certificateDetails, setCertificateDetails] = useState(null);
  const [detailsError, setDetailsError] = useState(null);
  const [isNeedUpdate, setIsNeedUpdate] = useState(true);

  useEffect(() => {
    if(isNeedUpdate) {
      (async () => {
        try {
          setSystemInfo({
            ...await getSystemInfo(),
            isValidSystemSetup: await isValidSystemSetup()
          });
          setIsNeedUpdate(false);
        } catch (error) {
          setSystemInfoError(error.message);
        }
      })();
    }
  }, []);

  return [systemInfo, systemInfoError];
};

