import React, {useState} from "react";
import {epdAstralService} from "../../../../services";
import {Button, Col, Form, Icon, Input, Modal, notification, Row} from "antd";
import InputField from "../../../input-field";

export default function ModalRegisterApiUser({isShow, onCloseModal, onSubmit, fieldsErrors}) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  let bigButtonStyle = {
    width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px'
  };
  const getFieldErrors = (nameField) => {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined") ? fieldsErrors[nameField][0] : null;
  };
  const registerApiUser = () => {
    if (username.length > 0 && password.length > 0) {
      onSubmit(username,password);
    }
  }

  return (<Modal
      title={<div>Логин в системе ЭДО ЭТрН</div>}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        onCloseModal(false);
      }}
      forceRender={true}
      footer={
        <Button htmlType="submit" key="submit" type="primary"
                style={bigButtonStyle}
                onClick={() => registerApiUser()}
                disabled={username.length === 0 || password.length === 0}
        >Отправить</Button>
      }
    >
      <Row gutter={[16, 24]} style={{marginBottom: "34px"}}>
        <Col span={24}>
          <InputField
            name="username" label="Логин"
            value={username} setValue={setUsername}
            getFieldErrors={getFieldErrors}
          />
        </Col>
        <Col span={24}>
          <InputField
            name="password" label="Пароль" type="password"
            value={password} setValue={setPassword}
            getFieldErrors={getFieldErrors}
          />
        </Col>
      </Row>
    </Modal>
  )
}
