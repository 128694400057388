import React from "react";
import {
  FORM_FIELDS_BEL_LEGAL_ENTITY,
  FORM_FIELDS_KAZ_LEGAL_ENTITY,
  FORM_FIELDS_RUS_LEGAL_ENTITY
} from "../../../helpers/doc_type.helper";
import {Col, Descriptions, List, Modal, Row, Spin} from "antd";

export default function ModalListChangesForApprove({countryId, params, isShow, setIsShow}) {

  let fields;
  countryId = 1;
  switch (countryId) {
    default:
    case 1:
      fields = FORM_FIELDS_RUS_LEGAL_ENTITY;
      break;
    case 6:
      fields = FORM_FIELDS_KAZ_LEGAL_ENTITY;
      break;
    case 3:
      fields = FORM_FIELDS_BEL_LEGAL_ENTITY;
      break;
  }

  return (
    <Modal
      title={`Внесенные изменения`}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        setIsShow(null);
      }}
      footer={
        <div style={{marginTop: "10"}}>
        </div>
      }
    >
      <Row gutter={16}>
        <Col span={24}>
          <List
            itemLayout="horizontal"
            dataSource={params}
            renderItem={(item) => {
              let newValue = '';
              let oldValue = '';
              let title = '';

              for (const [key, value] of Object.entries(item)) {
                title = fields
                  .filter((el) => el.key.toUpperCase() === key.toUpperCase())
                  .map((el) => el.title)
                  .toString();
                newValue = value.new;
                oldValue = value["old"];
              }

              return (
                <List.Item>
                  <List.Item.Meta
                    title={''}
                    description={
                      <Descriptions title={title} bordered>
                        <Descriptions.Item label="Старое значение: ">{newValue}</Descriptions.Item>
                        <Descriptions.Item label="Новое значение: ">{oldValue}</Descriptions.Item>
                      </Descriptions>
                    }
                  />
                </List.Item>
              )
            }}
          />
        </Col>
      </Row>
    </Modal>
  );
}
