import React, {useEffect, useState} from "react";
import {epdAstralService} from "../../../../services";
import {Button, Col, Form, Icon, Input, Modal, notification, Row} from "antd";
import InputField from "../../../input-field";

export default function ModalProfileApiUser({profileData, isShow, onCloseModal, setIsNeedUpdate}) {
  const [fieldsErrors, setFieldsErrors] = useState(null);
  const [formFields, setFormFields] = useState({
    inn: '',
    snils: '',
    phone: '',
    name: '',
    surname: '',
    patronymic: ''
  });

  useEffect(() => {
    if(profileData !== null){
      setFormFields(profileData)
    }
  }, [profileData]);
  let bigButtonStyle = {
    width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px'
  };
  const getFieldErrors = (nameField) => {
    return (fieldsErrors && typeof fieldsErrors[nameField] !== "undefined") ? fieldsErrors[nameField][0] : null;
  };
  const editProfileApiUser = () => {
    epdAstralService.updateProfile({...formFields})
      .then(resp => {
        if (resp.status === 'success') {
          notification.success({
            message: "Обновление данных профиля пользователя ЭДО ЭТрН",
            description: resp.message,
          });
          onCloseModal(false);
          setIsNeedUpdate(true);
        } else if (resp.status === 'error') {
          setFieldsErrors(resp.errors);
          notification.error({
            message: "Обновление данных профиля пользователя ЭДО ЭТрН",
            description: resp.message,
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: "Обновление данных профиля пользователя ЭДО ЭТрН", description: err,
        });
      });
  }

  return (<Modal
      title={<div>Обновление данных профиля пользователя ЭДО ЭТрН</div>}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        onCloseModal(false);
      }}
      forceRender={true}
      footer={
        <Button htmlType="submit" key="submit" type="primary"
                style={bigButtonStyle}
                onClick={() => editProfileApiUser()}
        >Отправить</Button>
      }
    >
      <Row gutter={[16, 24]} style={{marginBottom: "34px"}}>
        <Col span={24}>
          <InputField
            name="inn" label="ИНН"
            value={formFields.inn} setValue={(value) => setFormFields({...formFields, inn: value})}
            getFieldErrors={getFieldErrors}
          />
        </Col>
        <Col span={24}>
          <InputField
            name="snils" label="СНИСЛС"
            value={formFields.snils} setValue={(value) => setFormFields({...formFields, snils: value})}
            getFieldErrors={getFieldErrors}
          />
        </Col>
        <Col span={24}>
          <InputField
            name="phone" label="Телефон"
            value={formFields.phone} setValue={(value) => setFormFields({...formFields, phone: value})}
            getFieldErrors={getFieldErrors}
          />
        </Col>
        <Col span={24}>
          <InputField
            name="name" label="Имя"
            value={formFields.name} setValue={(value) => setFormFields({...formFields, name: value})}
            getFieldErrors={getFieldErrors}
          />
        </Col>
        <Col span={24}>
          <InputField
            name="patronymic" label="Отчество"
            value={formFields.patronymic} setValue={(value) => setFormFields({...formFields, patronymic: value})}
            getFieldErrors={getFieldErrors}
          />
        </Col>
        <Col span={24}>
          <InputField
            name="surname" label="Фамилия"
            value={formFields.surname} setValue={(value) => setFormFields({...formFields, surname: value})}
            getFieldErrors={getFieldErrors}
          />
        </Col>
      </Row>
    </Modal>
  )
}
