import React, {useEffect, useState} from 'react';
import './style.less';
import MainMap from "../../main-page-blocks/map";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import Accordion from "../../main-page-blocks/accordion";
import MainHeader from "../../main-page-blocks/header";
import 'swiper/swiper.less';
import Fancybox from "../../main-page-blocks/fancybox";
import {questService} from "../../../services/guest.services";
import NewsList from "../../main-page-blocks/news-list";
import {notification} from "antd";
import MainFooter from "../../main-page-blocks/footer";
import CookiesMessage from "../../main-page-blocks/cookies";
import Cookies from "js-cookie";

export default function MainPage() {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [stats, setStats] = useState(null);
  const [news, setNews] = useState([]);
  const [isOpenPopover, setOpenPopover] =useState(null);

  const getCookies = () => {
    return !!Cookies.get(`cookies`);
  }

  const [isCookiesApproved, setCookiesApproved] = useState(getCookies());

  useEffect(() => {
    if (!dataLoaded) {
      questService
        .getStats()
        .then((response) => {
          setStats(response);
        })
        .catch(() => {
        });
      questService
        .getNewsList()
        .then((response) => {
          setNews(response.news);
        })
        .catch((err) => {
          notification.error({
            message: "Получение списка новостей",
            description: err,
          });
        });
      setDataLoaded(true);
    }
  }, [dataLoaded]);

  return <>
    <MainHeader/>
    <main className="content">
      <section className="main" style={{backgroundImage: "url(./img/main-bg.jpg)"}}>
        <div className="main__round main__round1"></div>
        <div className="main__round main__round2"></div>
        <div className="container">
          <div className="main__inner">
            <h1 className="main__title display1">
              Платформа для грузоотправителей
              и владельцев транспорта
            </h1>
            <div className="main__info">
              <div className="main__group">
                <div className="main__group-num">
                  3000+
                </div>
                <div className="main__group-title text16 bold-weight">
                  рейсов в месяц
                </div>
              </div>
              <div className="main__group">
                <div className="main__group-num">
                  5000+
                </div>
                <div className="main__group-title text16 bold-weight">
                  перевозчиков
                </div>
              </div>
              <div className="main__group">
                <div className="main__group-num">
                  25000+
                </div>
                <div className="main__group-title text16 bold-weight">
                  водителей
                </div>
              </div>
              <div className="main__only text16 bold-weight">
                Только проверенные
                грузовладельцы и перевозчики
              </div>
            </div>
            <div className="main__nav">
              <a href="/register" className="main__btn m-btn m-btn-primary m-btn-triangle-right">
                <span className="text16 bold-weight">
                    Стать партнёром
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="inspect">
        <div className="container">
          <div className="inspect__inner">
            <h2 className="inspect__title display4 gray-text">
              Только проверенные грузовладельцы
              и перевозчики
            </h2>
            <p className="inspect__desc display5 gray-middle-text">
              5 уровней проверки
              всех контрагентов:
            </p>
            <div className="inspect__items">
              <div className="inspect-card">
                <div className="inspect-card__top">
                  <div className="inspect-card__num">
                    1
                  </div>
                  <div className="inspect-card__info">
                  </div>
                  <div className="inspect-card__line">
                    <img src="./img/round.svg" alt=""/>
                  </div>
                </div>
                <div className="inspect-card__text text14 medium-weight gray-middle-text">
                  Полная первичная проверка документов логистом
                </div>
              </div>

              <div className="inspect-card">
                <div className="inspect-card__top">
                  <div className="inspect-card__num">
                    2
                  </div>
                  <div className="inspect-card__info">
                    <button className="inspect-card__question medium-weight text13" onClick={() => setOpenPopover("2")}>
                      ?
                    </button>
                    {isOpenPopover === "2" &&
                      <div className="inspect-card__popover">
                        <button className="inspect-card__modal-close" onClick={() => setOpenPopover(null)}>
                          <img src="./img/close.svg" alt=""/>
                        </button>
                        <p className="text14">
                          ИП: Свидетельство ОГРН (или Лист записи), Свидетельство ИНН/КПП (либо уведомление о постановке
                          на учет в налоговом органе), Паспорт с пропиской (1 страница, страница с актуальной
                          пропиской), Карточка предприятия с образцом подписи
                          <br/><br/>
                          ООО: Устав со всеми изменениями, свидетельство ИНН, свидетельство ОГРН, карточка
                          предприятия, паспорт (первая страница, страница с пропиской) , протокол об избрании
                          директора, договор аренды на офисное помещение
                        </p>
                      </div>
                    }
                  </div>
                  <div className="inspect-card__line">
                    <img src="./img/round.svg" alt=""/>
                  </div>
                </div>
                <div className="inspect-card__text text14 medium-weight gray-middle-text">
                  Проверка юридическим отделом
                </div>
              </div>

              <div className="inspect-card">
                <div className="inspect-card__top">
                  <div className="inspect-card__num">
                    3
                  </div>
                  <div className="inspect-card__info">
                    <button className="inspect-card__question medium-weight text13" onClick={() => setOpenPopover("3")}>
                      ?
                    </button>
                    {isOpenPopover === "3" &&
                    <div className="inspect-card__popover">
                      <button className="inspect-card__modal-close" onClick={() => setOpenPopover(null)}>
                        <img src="./img/close.svg" alt=""/>
                      </button>
                      <p className="text14">
                        ИП с НДС: налоговая декларация по прибыли за последний отчетный период, налоговая декларация
                        по НДС за последний отчетный период, бухгалтерский баланс за последний отчетный период,
                        справка из ИФНС о состоянии расчетов с бюджетом, штатное расписание.
                        <br/><br/>
                        ООО с НДС: Договор аренды (если контрагент арендует площадь, на которой размещен офис
                        компании) или иные документы подтверждающие право собственности, карточка предприятия,
                        Бухгалтерский баланс за последний отчетный период, налоговую декларацию по прибыли за
                        последний отчетный период , налоговую декларацию по НДС за последний отчетный
                        период,Карточка предприятия с образцом подписи (в случае наличия печати – оттиск печати),
                        справку из ИФНС о состоянии расчетов с бюджетом, штатное расписание.</p>
                    </div>
                    }
                  </div>
                  <div className="inspect-card__line">
                    <img src="./img/round.svg" alt=""/>
                  </div>
                </div>
                <div className="inspect-card__text text14 medium-weight gray-middle-text">
                  Проверка бухгалтерским отделом
                </div>
              </div>

              <div className="inspect-card">
                <div className="inspect-card__top">
                  <div className="inspect-card__num">
                    4
                  </div>
                  <div className="inspect-card__info">
                    <button className="inspect-card__question medium-weight text13" onClick={() => setOpenPopover("4")}>
                      ?
                    </button>
                    {isOpenPopover === "4" &&
                      <div className="inspect-card__popover">
                        <button className="inspect-card__modal-close" onClick={() => setOpenPopover(null)}>
                          <img src="./img/close.svg" alt=""/>
                        </button>
                        <p className="text14">
                          Проверка: паспорт, водительское удостоверение, документы на транспортное средство
                        </p>
                      </div>
                    }
                  </div>
                  <div className="inspect-card__line">
                    <img src="./img/round.svg" alt=""/>
                  </div>
                </div>
                <div className="inspect-card__text text14 medium-weight gray-middle-text">
                  Проверка внутренней службой безопасности
                </div>
              </div>

              <div className="inspect-card">
                <div className="inspect-card__top">
                  <div className="inspect-card__num">
                    5
                  </div>
                  <div className="inspect-card__info">
                    <button className="inspect-card__question medium-weight text13" onClick={() => setOpenPopover("5")}>
                        ?
                    </button>
                    {(isOpenPopover === "5") &&
                      <div className="inspect-card__popover">
                        <button className="inspect-card__modal-close" onClick={()=>setOpenPopover(null)}>
                          <img src="./img/close.svg" alt=""/>
                        </button>
                        <p className="text14">
                          Проверка: сверка VIN номеров, сверка оригиналов документов. Проверка целостности
                          машины и тента.
                        </p>
                      </div>
                    }
                  </div>
                  <div className="inspect-card__line">
                    <img src="./img/round.svg" alt=""/>
                  </div>
                </div>
                <div className="inspect-card__text text14 medium-weight gray-middle-text">
                  Проверка водителя и ТС перед непосредственной погрузкой
                </div>
              </div>

            </div>
            <div className="inspect__media">
              <img src="./img/triangle.svg" alt="" className="inspect__triangle inspect__triangle-desktop"/>
              <img src="./img/triangle2.svg" alt="" className="inspect__triangle inspect__triangle-mob"/>
              <img src="./img/inspect.png" alt="" className="inspect__img"/>
            </div>
          </div>
        </div>
      </section>

      <section className="advantages">
        <div className="container">
          <div className="advantages__grid">
            <div className="advantage-card">
              <h3 className="advantage-card__title display4 gray-text">
                Только актуальные рейсы
              </h3>
              <ul className="advantage-card__list m-ul">
                <li>Актуальность рейсов проверяется системой каждые 4 часа</li>
                <li>Через платформу уже прошло более 22000 ТС</li>
                <li>При оформлении рейса можно сразу выбрать обратный</li>
              </ul>
              <img src="./img/advantage.webp" alt="" className="advantage-card__img"/>
            </div>

            <div className="advantage-card">
              <h3 className="advantage-card__title display4 gray-text">
                Полная автоматизация выкладки и оформления рейса
              </h3>
              <ul className="advantage-card__list m-ul">
                <li>ЭДО</li>
                <li>Никаких печатных документов</li>
                <li>Рейсы оформляются автоматически</li>
              </ul>
              <img src="./img/advantage2.webp" alt="" className="advantage-card__img"/>
            </div>

            <div className="advantage-card">
              <h3 className="advantage-card__title display4 gray-text">
                Мультимодальные перевозки
              </h3>
              <ul className="advantage-card__list m-ul">
                <li>По морю</li>
                <li>Железнодорожные</li>
                <li>Автотранспорт</li>
              </ul>
              <img src="./img/advantage3.webp" alt="" className="advantage-card__img"/>
            </div>

          </div>
        </div>
      </section>

      <section className="review">
        <div className="container">
          <div className="review__inner"
               style={{backgroundImage: "linear-gradient(163.85deg, rgba(73, 70, 41, 0.7) 11.23%, rgba(31, 90, 79, 0) 65.24%), url(./img/review.jpg)"}}>
            <div className="review__info">
              <h2 className="review__title display3">
                Обзор платформы
              </h2>
              <p className="review__desc text16 semi-bold-weight">
                Посмотрите видеопрезентацию, которая расскажет об основном функционале платформы
              </p>
            </div>
            <Fancybox
              options={{
                Carousel: {
                  infinite: false,
                },
                Toolbar: {
                  display: {
                    left: [],
                    middle: [],
                    right: ["close"],
                  },
                },
              }}
            >
              <a data-fancybox="video-gallery" href="https://www.youtube.com/watch?v=TVTfsOAlBhc"
                 className="video-btn">
                          <span className="video-btn__rounds">
                              <span className="video-btn__round video-btn__round1"></span>
                              <span className="video-btn__round video-btn__round2"></span>
                              <span className="video-btn__round video-btn__round3"></span>
                          </span>
                <img src="./img/play.svg" alt=""/>
              </a>
            </Fancybox>

            {/*<a href="" className="review__pdf m-btn m-btn-primary m-btn-triangle-right">*/}
            {/*  <img src="./img/pdf.svg" alt=""/>*/}
            {/*  <span className="text16 bold-weight">*/}
            {/*                    Скачать презентацию в pdf*/}
            {/*                </span>*/}
            {/*</a>*/}
          </div>
        </div>
      </section>

      <section className="help">
        <div className="help__col"
             style={{backgroundImage: "linear-gradient(67.46deg, rgba(73, 70, 41, 0.54) 0%, rgba(68, 128, 63, 0.9) 100%), url(./img/help.jpg)"}}>
          <div className="help__top">
            <h3 className="display3">
              Грузоотправителям
            </h3>
            <p className="text16 semi-bold-weight">
              Выберите свободного исполнителя из более 5000 проверенных перевозчиков
            </p>
          </div>
          <div className="help__bottom">
            <Fancybox
              options={{
                Carousel: {
                  infinite: false,
                },
                Toolbar: {
                  display: {
                    left: [],
                    middle: [],
                    right: ["close"],
                  },
                },
              }}
            >
              <div data-fancybox="" data-src="#for-consignor"
                   className="help__btn m-btn m-btn-primary m-btn-triangle-left">
                <span className="text16 bold-weight">
                    Найти транспорт
                </span>
              </div>
            </Fancybox>
          </div>
          <img src="./img/help-decor.svg" alt="" className="help__decor"/>
        </div>

        <div className="help__col"
             style={{backgroundImage: "linear-gradient(247.57deg, rgba(73, 70, 41, 0.9) -0.45%, rgba(68, 128, 63, 0.54) 100%), url(./img/help2.jpg)"}}>
          <div className="help__top">
            <h3 className="display3">
              Перевозчикам
            </h3>
            <p className="text16 semi-bold-weight">
              Найдите подходящий груз по вашим параметрам. Ежемесячный объем из более чем 3000 рейсов в месяц от
              проверенных грузовладельцев
            </p>
          </div>
          <div className="help__bottom">
            <a href="/deliveries/dashboard-deliveries"
               className="help__btn m-btn m-btn-primary m-btn-triangle-right">
            <span className="text16 bold-weight">
                Выбрать груз
            </span>
            </a>
          </div>
          <img src="./img/help-decor2.svg" alt="" className="help__decor"/>
        </div>

      </section>

      <section className="develop">
        <div className="container">
          <div className="develop__inner">
            <div className="develop__left">
              <div className="develop__badge m-badge">
                <span className="text16 semi-bold-weight green-text">
                    В разработке
                </span>
              </div>
              <h2 className="develop__title display4">
                Разрабатываем функционал
                кросс-докинга:
              </h2>
              <p className="develop__desc text20 medium-weight">
                создание и отправка продукции
                в продуктовые сети, для наших партнеров по отрасли.
              </p>
            </div>
            <div className="develop__right">
              <img src="./img/develop.svg" alt="" className="develop__img"/>
              <img src="./img/develop-decor.svg" alt="" className="develop__decor develop__decor-desktop"/>
              <img src="./img/develop-decor2.svg" alt="" className="develop__decor develop__decor-mob"/>
            </div>
          </div>
        </div>
      </section>

      <section className="tariffs">
        <div className="container">
          <div className="tariffs__top">
            <h2 className="tariffs__title display2">
              <span className="custom-line">Тарифы</span> для грузовладельцев
            </h2>
            <div className="tariffs__badge m-badge">
              <span className="text16 semi-bold-weight">
                  Для грузоперевозчиков сервис абсолютно бесплатный
              </span>
            </div>
          </div>
          <div className="tariffs__grid">
            <div className="tariff-card">
              <div className="tariff-card__title display5">
                3 месяца бесплатно
              </div>
              <div className="tariff-card__items">
                <div className="tariff-card__item">
                  Создать сотрудника
                </div>
                <div className="tariff-card__item">
                  Создать рейс
                </div>
                <div className="tariff-card__item">
                  Статистика по отмененным *рейсам
                </div>
                <div className="tariff-card__item ">
                  Статистика по грузам
                </div>
                <div className="tariff-card__item ">
                  Статистика по времени отклика
                </div>
                <div className="tariff-card__item ">
                  Статистика по стоимости километра
                </div>
                <div className="tariff-card__item ">
                  Статистика по переплатам
                </div>
                <div className="tariff-card__item _rouble">
                  Куратор от "Светофор Логистика"
                </div>
              </div>
              <a href="/register"
                 className="tariff-card__btn m-btn m-btn-primary m-btn-triangle-right">
                <span className="text16 bold-weight">
                    Начать
                </span>
              </a>
            </div>

            <div className="tariff-card">
              <div className="tariff-card__title display5">
                3000₽ за год
              </div>
              <div className="tariff-card__items">
                <div className="tariff-card__item">
                  Создать сотрудника
                </div>
                <div className="tariff-card__item">
                  Создать рейс
                </div>
                <div className="tariff-card__item">
                  Статистика по отмененным *рейсам
                </div>
                <div className="tariff-card__item _disabled">
                  Статистика по грузам
                </div>
                <div className="tariff-card__item _disabled">
                  Статистика по времени отклика
                </div>
                <div className="tariff-card__item _disabled">
                  Статистика по стоимости километра
                </div>
                <div className="tariff-card__item _disabled">
                  Статистика по переплатам
                </div>
                <div className="tariff-card__item _rouble">
                  Куратор от "Светофор Логистика"
                </div>
              </div>
              <a href="/register"
                 className="tariff-card__btn m-btn m-btn-primary m-btn-triangle-right">
                <span className="text16 bold-weight">
                    Начать
                </span>
              </a>
            </div>

            <div className="tariff-card">
              <div className="tariff-card__title display5">
                5000₽ за год
              </div>
              <div className="tariff-card__items">
                <div className="tariff-card__item">
                  Создать сотрудника
                </div>
                <div className="tariff-card__item">
                  Создать рейс
                </div>
                <div className="tariff-card__item">
                  Статистика по отмененным *рейсам
                </div>
                <div className="tariff-card__item ">
                  Статистика по грузам
                </div>
                <div className="tariff-card__item ">
                  Статистика по времени отклика
                </div>
                <div className="tariff-card__item ">
                  Статистика по стоимости километра
                </div>
                <div className="tariff-card__item ">
                  Статистика по переплатам
                </div>
                <div className="tariff-card__item _rouble">
                  Куратор от "Светофор Логистика"
                </div>
              </div>
              <a href="/register"
                 className="tariff-card__btn m-btn m-btn-primary m-btn-triangle-right">
                <span className="text16 bold-weight">
                    Начать
                </span>
              </a>
            </div>

          </div>
        </div>
      </section>

      <section className="how-work">
        <div className="container">
          <h2 className="how-work__title display2">
            Как мы
            <span className="custom-line"> работаем</span>
          </h2>
          <div className="how-work__grid">
            <div className="step-card">
              <div className="step-card__content">
                <div className="step-card__img">
                  <img src="./img/step.svg" alt=""/>
                </div>

                <div className="step-card__top">
                  <div className="step-card__title display4 yellow-text">
                    Шаг 1
                  </div>
                  <div className="step-card__line">
                    <img src="./img/round.svg" alt=""/>
                  </div>
                </div>

                <div className="step-card__desc display5">
                  Регистрация
                </div>
              </div>
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                  Toolbar: {
                    display: {
                      left: [],
                      middle: [],
                      right: ["close"],
                    },
                  },
                }}
              >
                <a data-fancybox="" href="./img/work/work1.png" className="step-card__content2">
                  <img className="step-card__img2" src="./img/work/work1.png" alt=""/>
                  <img src="./img/zoom.svg" alt="" className="step-card__zoom"/>
                  <div className="step-card__round-wrap">
                    <span className="step-card__round"></span>
                  </div>
                </a>
              </Fancybox>
            </div>

            <div className="step-card">
              <div className="step-card__content">
                <div className="step-card__img">
                  <img src="./img/step2.svg" alt=""/>
                </div>

                <div className="step-card__top">
                  <div className="step-card__title display4 yellow-text">
                    Шаг 2
                  </div>
                  <div className="step-card__line">
                    <img src="./img/round.svg" alt=""/>
                  </div>
                </div>

                <div className="step-card__desc display5">
                  Добавление данных юрлица в систему
                </div>
              </div>
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                  Toolbar: {
                    display: {
                      left: [],
                      middle: [],
                      right: ["close"],
                    },
                  },
                }}
              >
                <a data-fancybox="" href="./img/work/work2.png" className="step-card__content2">
                  <img className="step-card__img2" src="./img/work/work2.png" alt=""/>
                  <img src="./img/zoom.svg" alt="" className="step-card__zoom"/>
                  <div className="step-card__round-wrap">
                    <span className="step-card__round"></span>
                  </div>
                </a>
              </Fancybox>
            </div>

            <div className="step-card">
              <div className="step-card__content">
                <div className="step-card__img">
                  <img src="./img/step3.svg" alt=""/>
                </div>

                <div className="step-card__top">
                  <div className="step-card__title display4 yellow-text">
                    Шаг 3
                  </div>
                  <div className="step-card__line">
                    <img src="./img/round.svg" alt=""/>
                  </div>
                </div>

                <div className="step-card__desc display5">
                  Прикрепление документов
                </div>
              </div>
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                  Toolbar: {
                    display: {
                      left: [],
                      middle: [],
                      right: ["close"],
                    },
                  },
                }}
              >
                <a data-fancybox="" href="./img/work/work3.png" className="step-card__content2">
                  <img className="step-card__img2" src="./img/work/work3.png" alt=""/>
                  <img src="./img/zoom.svg" alt="" className="step-card__zoom"/>
                  <div className="step-card__round-wrap">
                    <span className="step-card__round"></span>
                  </div>
                </a>
              </Fancybox>
            </div>

            <div className="step-card">
              <div className="step-card__content">
                <div className="step-card__img">
                  <img src="./img/step4.svg" alt=""/>
                </div>

                <div className="step-card__top">
                  <div className="step-card__title display4 yellow-text">
                    Шаг 4
                  </div>
                  <div className="step-card__line">
                    <img src="./img/round.svg" alt=""/>
                  </div>
                </div>

                <div className="step-card__desc display5">
                  Выбор контрагентов
                </div>
              </div>
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                  Toolbar: {
                    display: {
                      left: [],
                      middle: [],
                      right: ["close"],
                    },
                  },
                }}
              >
                <a data-fancybox="" href="./img/work/work4.png" className="step-card__content2">
                  <img className="step-card__img2" src="./img/work/work4.png" alt=""/>
                  <img src="./img/zoom.svg" alt="" className="step-card__zoom"/>
                  <div className="step-card__round-wrap">
                    <span className="step-card__round"></span>
                  </div>
                </a>
              </Fancybox>
            </div>

          </div>
        </div>
      </section>

      <section className="routes">
        <h2 className="routes__title display2">
          Рейсы доступны
          <span className="custom-line"> по всей РФ и СНГ</span>
        </h2>
        <MainMap/>
      </section>

      <section className="service">
        <div className="container">
          <div className="service__inner">
            <div className="service__left">
              <div className="service__top">
                <h2 className="service__title display2">
                  Сервис <br/> доступен
                  <span className="custom-line"> 24/7</span>
                </h2>
                <div className="service__desc text20">
                  <p>Вы можете пользоваться сервисом с помощью сайта, мобильных приложений и телеграм бота.</p>
                  <p>С помощью телеграм бота или мобильного приложения вы можете получать информацию об актуальных
                    рейсах, а так же брать их в работу.</p>
                </div>
              </div>
              <div className="service__hours">
                <img src="./img/clock.png" alt="" className="service__hours-img"/>
                <div className="service__hours-bg"></div>
              </div>
            </div>
            <div className="service__right">
              <div className="service__slider">
                <div className="swiper-container">
                  <Swiper
                    spaceBetween={28}
                    pagination={{
                      type: "fraction",
                      el: '.service .swiper-pagination',
                    }}
                    navigation={{
                      nextEl: '.service .swiper-button-next',
                      prevEl: '.service .swiper-button-prev',
                    }}
                    breakpoints={{
                      320: {
                        spaceBetween: 4,
                      },
                      601: {
                        spaceBetween: 28,
                      }
                    }}
                    modules={[Pagination, Navigation]}
                  >
                    <SwiperSlide>
                      <>
                        <div className="chat">
                          <img src="./img/screen/screen1.jpg" alt=""/>
                        </div>
                        <div className="chat__text text20 bold-weight">
                          Актуальные рейсы в чат боте
                        </div>
                      </>
                    </SwiperSlide>
                    <SwiperSlide>
                      <>
                        <div className="chat">
                          <img src="./img/screen/screen2.jpg" alt=""/>
                        </div>
                        <div className="chat__text text20 bold-weight">
                          Актуальные рейсы в мобильном приложении
                        </div>
                      </>
                    </SwiperSlide>
                    <SwiperSlide>
                      <>
                        <div className="chat">
                          <img src="./img/screen/screen3.jpg" alt=""/>
                        </div>
                        <div className="chat__text text20 bold-weight">
                          Заявка на рейс в чат боте
                        </div>
                      </>
                    </SwiperSlide>
                    <SwiperSlide>
                      <>
                        <div className="chat">
                          <img src="./img/screen/screen4.jpg" alt=""/>
                        </div>
                        <div className="chat__text text20 bold-weight">
                          Заявка на рейс в мобильном приложении
                        </div>
                      </>
                    </SwiperSlide>
                    <SwiperSlide>
                      <>
                        <div className="chat">
                          <img src="./img/screen/screen5.jpg" alt=""/>
                        </div>
                        <div className="chat__text text20 bold-weight">
                          Уведомление о встречном предложении
                        </div>
                      </>
                    </SwiperSlide>
                  </Swiper>

                  <div className="service__nav">
                    <div className="swiper-pagination"></div>
                    <div className="service__buttons">
                      <button className="swiper-button swiper-button-prev m-btn m-btn-primary m-btn-triangle-left">
                        <img src="./img/prev.svg" alt=""/>
                      </button>
                      <button className="swiper-button swiper-button-next m-btn m-btn-primary m-btn-triangle-right">
                        <img src="./img/next.svg" alt=""/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="qa">
        <div className="container">
          <h2 className="qa__title display2">
            <span className="custom-line">Ответы </span>
            на вопросы
          </h2>
          {<Accordion/>}
        </div>
      </section>

      {news.length && <NewsList news={news}/>}
    </main>
    <MainFooter/>
    {!isCookiesApproved && <CookiesMessage setCookiesApproved={setCookiesApproved}/>}
    <div className="news-modal" id="for-consignor" style={{display: "none", maxWidth: "600px"}}>
      <div className="news-modal__dialog">
        <div className="news-card__date text14"/>
        <h3 className="news-card__title display4">
          Поиск транспорта для грузоотправителей
        </h3>
        <p className="news-card__desc text20">
          1. Зарегистрируйтесь на платформе
        </p>
        <p className="news-card__desc text20">
          2. Добавьте на платформу вашу компанию и подайте заявку на присвоение статуса грузоотправителя
        </p>
        <p className="news-card__desc text20">
          3. После проверки вы сможете выкладывать свои рейсы.
        </p>
        <p className="news-card__desc text20">
          4. Перевозчики будут самостоятельно подавать вам заявки и заполнять их
        </p>
      </div>
    </div>
  </>;
};
