import React, {useContext, useEffect, useState} from "react";
import {Select, Row, Col, Button, Cascader} from "antd";
import {AppContext} from "../../contexts/app-context";
import GoogleAndroidAppLink from "../google-android-app-link";
import Cookies from 'js-cookie';
import {useLocation} from "react-router-dom";
import RegionSelect from "./region-select";

export default function TopFilter() {
  const {Option} = Select;
  const appContext = useContext(AppContext);
  const [clearFilterFlag, setClearFilterFlag] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const path = useLocation().pathname.split("/").slice(-1);
  const {regionsLoadings, regionsUnloadings} = appContext.filter;

  const getCookies = (name) => {
    let value = {};
    if (Cookies.get(`${name}-${path}`)) {
      Cookies.get(`${name}-${path}`)
        .split(",")
        .map((item) => {
          const itemArr = item.split(': ')
          if (itemArr[1] !== '') {
            Object.assign(value, {[itemArr[0]]: itemArr[1].split('|').map((item) => Number(item))});
          } else {
            Object.assign(value, {[itemArr[0]]: []});
          }
        })
    }
    return value;
  }

  const [selectedRegionsLoading, setSelectedRegionsLoading] = useState(getCookies("regionsLoading"));
  const [selectedRegionsUnloading, setSelectedRegionsUnloading] = useState(getCookies("regionsUnloading"));
  const [selectedSorting, setSelectedSorting] = useState(appContext.filter.selectedSorting);

  const isEmptyFilter = !Boolean(Object.keys(selectedRegionsLoading).length) && !Boolean(Object.keys(selectedRegionsUnloading).length) && selectedSorting === 'default';

  const onChangeRegionLoading = (values) => {
    setSelectedRegionsLoading(values);
  };
  const onChangeRegionUnloading = (values) => {
    setSelectedRegionsUnloading(values);
  };
  const onChangeFilterSort = (value) => {
    setSelectedSorting(value);
  };

  const setCookies = (name, values) => {
    if (values) {
      const valuesArr = [];
      for (const [key, value] of Object.entries(values)) {
        valuesArr.push(`${key}: ${value.toString().replaceAll(',', '|')}`);
      }
      Cookies.set(`${name}-${path}`, valuesArr.join());
    } else if (Cookies.get(`${name}-${path}`)) {
      Cookies.remove(`${name}-${path}`);
    }
  }

  useEffect(() => {
    if (!isFiltered && !isEmptyFilter || clearFilterFlag) {
      appContext.applyFilterParams(selectedRegionsLoading, selectedRegionsUnloading, selectedSorting);
      if (!isFiltered && !isEmptyFilter) {
        setIsFiltered(true);
        setClearFilterFlag(true);
      }
    }
  }, [isFiltered, clearFilterFlag]);

  const applyFilterParams = () => {
    appContext.applyFilterParams(selectedRegionsLoading, selectedRegionsUnloading, selectedSorting);
    if (!isEmptyFilter) {
      setIsFiltered(true);
      setClearFilterFlag(true);
    }
    setCookies('regionsLoading', selectedRegionsLoading);
    setCookies('regionsUnloading', selectedRegionsUnloading);
  };

  const clearFilterParams = () => {
    setSelectedRegionsUnloading({});
    setSelectedRegionsLoading({});
    setSelectedSorting('default');

    setCookies('regionsLoading', {});
    setCookies('regionsUnloading', {});
    if (isFiltered) {
      setIsFiltered(false);
    }
  };

  function filter(inputValue, path) {
    return path.some(option => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  }

  const sortItems = appContext.filter.sort;
  const applyGoogleAndroidAppLink = appContext.user.isGuest();

  function onChange(value) {
    console.log(value);
  }

  return (
    <div className="filters-container">
      <div className="container">
        <div className="filters-container__wrapper">
          <Row gutter={22} style={{padding: "0", margin: "0", rowGap: "10px"}} type="flex" justify="start">
            <Col className="first-filter" xs={12} sm={12} md={8} lg={8} xl={8} style={{}}>
              <div style={{position: 'relative', height: '100%'}} id="regionsLoadings">
                <RegionSelect
                  regions={regionsLoadings}
                  prefix={'regionsLoadings'}
                  placeholder={'Выберите регион загрузки'}
                  onChange={onChangeRegionLoading}
                  defaultValue={selectedRegionsLoading}
                />
              </div>
            </Col>
            <Col className="first-filter" xs={12} sm={12} md={8} lg={8} xl={8} style={{}}>
              <div style={{position: 'relative', height: '100%'}} id="regionsUnloadings">
                <RegionSelect
                  regions={regionsUnloadings}
                  prefix={'regionsUnloadings'}
                  placeholder={'Выберите регион разгрузки'}
                  onChange={onChangeRegionUnloading}
                  defaultValue={selectedRegionsUnloading}
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={5} lg={5} xl={5} style={{}}>
              <Select
                size="default"
                onChange={(value) => {
                  onChangeFilterSort(value);
                }}
                defaultValue={selectedSorting}
                value={selectedSorting}
                style={{width: "100%", height: "100%"}}
              >
                {sortItems.map((sortItem) => {
                  return (
                    <Option key={sortItem.name} value={sortItem.name} selected={sortItem.selected}>
                      {sortItem.title}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col className="filters-buttons" xs={12} sm={12} md={3} lg={3} xl={3} style={{}}>
              <Button
                className="filters-button"
                type="primary"
                size="default"
                icon="search"
                htmlType="button"
                title="Применить параметры фильтра"
                onClick={applyFilterParams}
              />
              <Button
                className="filters-button filters-button--clear"
                type="primary"
                size="default"
                icon="close"
                disabled={isEmptyFilter}
                htmlType="button"
                title="Очистить параметры фильтра"
                style={{border: "none", outline: "none", marginLeft: "5px"}}
                onClick={clearFilterParams}
              />
            </Col>
          </Row>
        </div>
      </div>
      {/*<YoutubeVideoLink />*/}
      {applyGoogleAndroidAppLink ? <GoogleAndroidAppLink/> : null}
    </div>
  );
}
